.home-grid {
  margin: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 6rem 1fr 1fr 1fr;
  grid-row-gap: 1.75rem;
  grid-column-gap: 1rem;
  min-width: 52rem;
  margin-bottom: 2rem;
}
.home {
  background: #F2F2F3;
  display: inline-block;
  width: 100%;
	/* overflow-y: scroll; */
	position: relative;
}
/* .main > div:nth-of-type(2) {
  margin: 1rem;
} */
#missed-calls,
#new-voicemails,
#new-fax,
#new-messages {
  min-width: 12rem;
  vertical-align: middle;
}

#missed-calls >*,
#new-voicemails >*,
#new-fax >*,
#new-messages >* {
  margin-top: 0.5rem;
  margin-right: 0.4rem;
}

.home-grid > div {
  background: white;
  border-radius: 0.2rem;
  box-shadow: 0px 3px 10px 1px #ddd;
  height: 100%;
  position: relative;
  padding-bottom: 1rem;
}

.home-grid div h1 {
  float: right;
  margin: 0;
  padding: 0;
}

.home-grid div h4 {
  font-size: 1rem;
  font-weight: 300;
  padding: 0.75rem;
  margin: 0;
  float: left;
}

.view-all {
  color: #1E81FF;
  float: right;
  padding-right: 15px;
  padding-top: 10px;
  cursor: pointer;
}

.home-grid div > p {
  float: right;
}
.common-box{
  background: #FFFFFF;
  width: 100%;
  text-align: center;
  line-height: 2rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
  padding: 15px 20px 20px 20px;
  margin-right: 1px;
  float: left;
}
#missed-calls{
  border-top: 2px solid #FC231C;
}

#new-voicemails {
  border-top: 2px solid #2DCB1A;
}

#new-fax {
  border-top: 2px solid #F4F4F5;
}

#new-messages {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
}

#missed-calls .missed-content,
#new-voicemails .voicemail-content,
#new-fax .total-fax-content,
#new-messages img {
  float: left;
  margin-left: 1rem;
}

.text {
  float: right;
  font-weight: 300;
}
.text .text-count {
  display: block;
  padding-bottom: 0;
  font-size: 32px;
  font-weight: normal;
}
.text .text-value {
  font-size: 14px;
  font-weight: normal;
}
#devices thead,
#numbers thead,
#conferences thead {
  background: none;
}

.blue {
  color: #1E81FF;
}

#numbers select {
  width: 100%;
  font-size: 1.1rem;
}

#num {
  font-size: 32px;
  font-weight: 400;
  color: black;
  margin-right: 0.3rem;
}
#num .num-title{
  font-size: 14px;
  color: gray;
}

#num:nth-of-type(2) {
  margin-left: 1.5rem;
}

#numbers {
  height: 100%;
}

#numbers td {
  padding-top: 1rem;
}

#faxes {
  grid-row: 4 / 5;
  grid-column: 1 / 3;
}

#conferences {
  grid-row: 4 / 5;
  grid-column: 3 / 5;
}

#devices table > tbody > tr > td,
#faxes table > tbody > tr > td
 {
  border-bottom: 1px solid #ddd;
}
.call-title{
  width: 100%;
  padding: 10px 10px 15px 5px;
  display: flex;
}
.missed-call-box{
  background: #FFFFFF;
  width: 100%;
  text-align: center;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
  padding: 10px 15px 12px 15px;
  float: left;
  height: 370px;
}
.calltable-thead th{
  padding-bottom: 10px !important;
}

.devices-box{
  background: #FFFFFF;
  width: 100%;
  text-align: center;
  line-height: 2rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
  padding: 10px 20px 20px 20px;
  float: left;
  height: 350px;
}

.loader_container{
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  z-index: 1048;
  background-color: black;
  opacity: 0.8;
  touch-action: none;
}
.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #ff2300;
  width: 120px;
  height: 120px;
  opacity: 1;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}