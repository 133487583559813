@import url(https://fonts.googleapis.com/css?family=Nunito:400,400i,600,700&display=swap&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@font-face {
	font-family: 'themify';
	src:url(../../static/media/themify.2c454669.eot);
	src:url(../../static/media/themify.2c454669.eot?#iefix-fvbane) format('embedded-opentype'),
		url(../../static/media/themify.a1ecc3b8.woff) format('woff'),
		url(../../static/media/themify.e23a7dca.ttf) format('truetype'),
		url(../../static/media/themify.9c8e96ec.svg#themify) format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="ti-"], [class*=" ti-"] {
	font-family: 'themify';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ti-wand:before {
	content: "\e600";
}
.ti-volume:before {
	content: "\e601";
}
.ti-user:before {
	content: "\e602";
}
.ti-unlock:before {
	content: "\e603";
}
.ti-unlink:before {
	content: "\e604";
}
.ti-trash:before {
	content: "\e605";
}
.ti-thought:before {
	content: "\e606";
}
.ti-target:before {
	content: "\e607";
}
.ti-tag:before {
	content: "\e608";
}
.ti-tablet:before {
	content: "\e609";
}
.ti-star:before {
	content: "\e60a";
}
.ti-spray:before {
	content: "\e60b";
}
.ti-signal:before {
	content: "\e60c";
}
.ti-shopping-cart:before {
	content: "\e60d";
}
.ti-shopping-cart-full:before {
	content: "\e60e";
}
.ti-settings:before {
	content: "\e60f";
}
.ti-search:before {
	content: "\e610";
}
.ti-zoom-in:before {
	content: "\e611";
}
.ti-zoom-out:before {
	content: "\e612";
}
.ti-cut:before {
	content: "\e613";
}
.ti-ruler:before {
	content: "\e614";
}
.ti-ruler-pencil:before {
	content: "\e615";
}
.ti-ruler-alt:before {
	content: "\e616";
}
.ti-bookmark:before {
	content: "\e617";
}
.ti-bookmark-alt:before {
	content: "\e618";
}
.ti-reload:before {
	content: "\e619";
}
.ti-plus:before {
	content: "\e61a";
}
.ti-pin:before {
	content: "\e61b";
}
.ti-pencil:before {
	content: "\e61c";
}
.ti-pencil-alt:before {
	content: "\e61d";
}
.ti-paint-roller:before {
	content: "\e61e";
}
.ti-paint-bucket:before {
	content: "\e61f";
}
.ti-na:before {
	content: "\e620";
}
.ti-mobile:before {
	content: "\e621";
}
.ti-minus:before {
	content: "\e622";
}
.ti-medall:before {
	content: "\e623";
}
.ti-medall-alt:before {
	content: "\e624";
}
.ti-marker:before {
	content: "\e625";
}
.ti-marker-alt:before {
	content: "\e626";
}
.ti-arrow-up:before {
	content: "\e627";
}
.ti-arrow-right:before {
	content: "\e628";
}
.ti-arrow-left:before {
	content: "\e629";
}
.ti-arrow-down:before {
	content: "\e62a";
}
.ti-lock:before {
	content: "\e62b";
}
.ti-location-arrow:before {
	content: "\e62c";
}
.ti-link:before {
	content: "\e62d";
}
.ti-layout:before {
	content: "\e62e";
}
.ti-layers:before {
	content: "\e62f";
}
.ti-layers-alt:before {
	content: "\e630";
}
.ti-key:before {
	content: "\e631";
}
.ti-import:before {
	content: "\e632";
}
.ti-image:before {
	content: "\e633";
}
.ti-heart:before {
	content: "\e634";
}
.ti-heart-broken:before {
	content: "\e635";
}
.ti-hand-stop:before {
	content: "\e636";
}
.ti-hand-open:before {
	content: "\e637";
}
.ti-hand-drag:before {
	content: "\e638";
}
.ti-folder:before {
	content: "\e639";
}
.ti-flag:before {
	content: "\e63a";
}
.ti-flag-alt:before {
	content: "\e63b";
}
.ti-flag-alt-2:before {
	content: "\e63c";
}
.ti-eye:before {
	content: "\e63d";
}
.ti-export:before {
	content: "\e63e";
}
.ti-exchange-vertical:before {
	content: "\e63f";
}
.ti-desktop:before {
	content: "\e640";
}
.ti-cup:before {
	content: "\e641";
}
.ti-crown:before {
	content: "\e642";
}
.ti-comments:before {
	content: "\e643";
}
.ti-comment:before {
	content: "\e644";
}
.ti-comment-alt:before {
	content: "\e645";
}
.ti-close:before {
	content: "\e646";
}
.ti-clip:before {
	content: "\e647";
}
.ti-angle-up:before {
	content: "\e648";
}
.ti-angle-right:before {
	content: "\e649";
}
.ti-angle-left:before {
	content: "\e64a";
}
.ti-angle-down:before {
	content: "\e64b";
}
.ti-check:before {
	content: "\e64c";
}
.ti-check-box:before {
	content: "\e64d";
}
.ti-camera:before {
	content: "\e64e";
}
.ti-announcement:before {
	content: "\e64f";
}
.ti-brush:before {
	content: "\e650";
}
.ti-briefcase:before {
	content: "\e651";
}
.ti-bolt:before {
	content: "\e652";
}
.ti-bolt-alt:before {
	content: "\e653";
}
.ti-blackboard:before {
	content: "\e654";
}
.ti-bag:before {
	content: "\e655";
}
.ti-move:before {
	content: "\e656";
}
.ti-arrows-vertical:before {
	content: "\e657";
}
.ti-arrows-horizontal:before {
	content: "\e658";
}
.ti-fullscreen:before {
	content: "\e659";
}
.ti-arrow-top-right:before {
	content: "\e65a";
}
.ti-arrow-top-left:before {
	content: "\e65b";
}
.ti-arrow-circle-up:before {
	content: "\e65c";
}
.ti-arrow-circle-right:before {
	content: "\e65d";
}
.ti-arrow-circle-left:before {
	content: "\e65e";
}
.ti-arrow-circle-down:before {
	content: "\e65f";
}
.ti-angle-double-up:before {
	content: "\e660";
}
.ti-angle-double-right:before {
	content: "\e661";
}
.ti-angle-double-left:before {
	content: "\e662";
}
.ti-angle-double-down:before {
	content: "\e663";
}
.ti-zip:before {
	content: "\e664";
}
.ti-world:before {
	content: "\e665";
}
.ti-wheelchair:before {
	content: "\e666";
}
.ti-view-list:before {
	content: "\e667";
}
.ti-view-list-alt:before {
	content: "\e668";
}
.ti-view-grid:before {
	content: "\e669";
}
.ti-uppercase:before {
	content: "\e66a";
}
.ti-upload:before {
	content: "\e66b";
}
.ti-underline:before {
	content: "\e66c";
}
.ti-truck:before {
	content: "\e66d";
}
.ti-timer:before {
	content: "\e66e";
}
.ti-ticket:before {
	content: "\e66f";
}
.ti-thumb-up:before {
	content: "\e670";
}
.ti-thumb-down:before {
	content: "\e671";
}
.ti-text:before {
	content: "\e672";
}
.ti-stats-up:before {
	content: "\e673";
}
.ti-stats-down:before {
	content: "\e674";
}
.ti-split-v:before {
	content: "\e675";
}
.ti-split-h:before {
	content: "\e676";
}
.ti-smallcap:before {
	content: "\e677";
}
.ti-shine:before {
	content: "\e678";
}
.ti-shift-right:before {
	content: "\e679";
}
.ti-shift-left:before {
	content: "\e67a";
}
.ti-shield:before {
	content: "\e67b";
}
.ti-notepad:before {
	content: "\e67c";
}
.ti-server:before {
	content: "\e67d";
}
.ti-quote-right:before {
	content: "\e67e";
}
.ti-quote-left:before {
	content: "\e67f";
}
.ti-pulse:before {
	content: "\e680";
}
.ti-printer:before {
	content: "\e681";
}
.ti-power-off:before {
	content: "\e682";
}
.ti-plug:before {
	content: "\e683";
}
.ti-pie-chart:before {
	content: "\e684";
}
.ti-paragraph:before {
	content: "\e685";
}
.ti-panel:before {
	content: "\e686";
}
.ti-package:before {
	content: "\e687";
}
.ti-music:before {
	content: "\e688";
}
.ti-music-alt:before {
	content: "\e689";
}
.ti-mouse:before {
	content: "\e68a";
}
.ti-mouse-alt:before {
	content: "\e68b";
}
.ti-money:before {
	content: "\e68c";
}
.ti-microphone:before {
	content: "\e68d";
}
.ti-menu:before {
	content: "\e68e";
}
.ti-menu-alt:before {
	content: "\e68f";
}
.ti-map:before {
	content: "\e690";
}
.ti-map-alt:before {
	content: "\e691";
}
.ti-loop:before {
	content: "\e692";
}
.ti-location-pin:before {
	content: "\e693";
}
.ti-list:before {
	content: "\e694";
}
.ti-light-bulb:before {
	content: "\e695";
}
.ti-Italic:before {
	content: "\e696";
}
.ti-info:before {
	content: "\e697";
}
.ti-infinite:before {
	content: "\e698";
}
.ti-id-badge:before {
	content: "\e699";
}
.ti-hummer:before {
	content: "\e69a";
}
.ti-home:before {
	content: "\e69b";
}
.ti-help:before {
	content: "\e69c";
}
.ti-headphone:before {
	content: "\e69d";
}
.ti-harddrives:before {
	content: "\e69e";
}
.ti-harddrive:before {
	content: "\e69f";
}
.ti-gift:before {
	content: "\e6a0";
}
.ti-game:before {
	content: "\e6a1";
}
.ti-filter:before {
	content: "\e6a2";
}
.ti-files:before {
	content: "\e6a3";
}
.ti-file:before {
	content: "\e6a4";
}
.ti-eraser:before {
	content: "\e6a5";
}
.ti-envelope:before {
	content: "\e6a6";
}
.ti-download:before {
	content: "\e6a7";
}
.ti-direction:before {
	content: "\e6a8";
}
.ti-direction-alt:before {
	content: "\e6a9";
}
.ti-dashboard:before {
	content: "\e6aa";
}
.ti-control-stop:before {
	content: "\e6ab";
}
.ti-control-shuffle:before {
	content: "\e6ac";
}
.ti-control-play:before {
	content: "\e6ad";
}
.ti-control-pause:before {
	content: "\e6ae";
}
.ti-control-forward:before {
	content: "\e6af";
}
.ti-control-backward:before {
	content: "\e6b0";
}
.ti-cloud:before {
	content: "\e6b1";
}
.ti-cloud-up:before {
	content: "\e6b2";
}
.ti-cloud-down:before {
	content: "\e6b3";
}
.ti-clipboard:before {
	content: "\e6b4";
}
.ti-car:before {
	content: "\e6b5";
}
.ti-calendar:before {
	content: "\e6b6";
}
.ti-book:before {
	content: "\e6b7";
}
.ti-bell:before {
	content: "\e6b8";
}
.ti-basketball:before {
	content: "\e6b9";
}
.ti-bar-chart:before {
	content: "\e6ba";
}
.ti-bar-chart-alt:before {
	content: "\e6bb";
}
.ti-back-right:before {
	content: "\e6bc";
}
.ti-back-left:before {
	content: "\e6bd";
}
.ti-arrows-corner:before {
	content: "\e6be";
}
.ti-archive:before {
	content: "\e6bf";
}
.ti-anchor:before {
	content: "\e6c0";
}
.ti-align-right:before {
	content: "\e6c1";
}
.ti-align-left:before {
	content: "\e6c2";
}
.ti-align-justify:before {
	content: "\e6c3";
}
.ti-align-center:before {
	content: "\e6c4";
}
.ti-alert:before {
	content: "\e6c5";
}
.ti-alarm-clock:before {
	content: "\e6c6";
}
.ti-agenda:before {
	content: "\e6c7";
}
.ti-write:before {
	content: "\e6c8";
}
.ti-window:before {
	content: "\e6c9";
}
.ti-widgetized:before {
	content: "\e6ca";
}
.ti-widget:before {
	content: "\e6cb";
}
.ti-widget-alt:before {
	content: "\e6cc";
}
.ti-wallet:before {
	content: "\e6cd";
}
.ti-video-clapper:before {
	content: "\e6ce";
}
.ti-video-camera:before {
	content: "\e6cf";
}
.ti-vector:before {
	content: "\e6d0";
}
.ti-themify-logo:before {
	content: "\e6d1";
}
.ti-themify-favicon:before {
	content: "\e6d2";
}
.ti-themify-favicon-alt:before {
	content: "\e6d3";
}
.ti-support:before {
	content: "\e6d4";
}
.ti-stamp:before {
	content: "\e6d5";
}
.ti-split-v-alt:before {
	content: "\e6d6";
}
.ti-slice:before {
	content: "\e6d7";
}
.ti-shortcode:before {
	content: "\e6d8";
}
.ti-shift-right-alt:before {
	content: "\e6d9";
}
.ti-shift-left-alt:before {
	content: "\e6da";
}
.ti-ruler-alt-2:before {
	content: "\e6db";
}
.ti-receipt:before {
	content: "\e6dc";
}
.ti-pin2:before {
	content: "\e6dd";
}
.ti-pin-alt:before {
	content: "\e6de";
}
.ti-pencil-alt2:before {
	content: "\e6df";
}
.ti-palette:before {
	content: "\e6e0";
}
.ti-more:before {
	content: "\e6e1";
}
.ti-more-alt:before {
	content: "\e6e2";
}
.ti-microphone-alt:before {
	content: "\e6e3";
}
.ti-magnet:before {
	content: "\e6e4";
}
.ti-line-double:before {
	content: "\e6e5";
}
.ti-line-dotted:before {
	content: "\e6e6";
}
.ti-line-dashed:before {
	content: "\e6e7";
}
.ti-layout-width-full:before {
	content: "\e6e8";
}
.ti-layout-width-default:before {
	content: "\e6e9";
}
.ti-layout-width-default-alt:before {
	content: "\e6ea";
}
.ti-layout-tab:before {
	content: "\e6eb";
}
.ti-layout-tab-window:before {
	content: "\e6ec";
}
.ti-layout-tab-v:before {
	content: "\e6ed";
}
.ti-layout-tab-min:before {
	content: "\e6ee";
}
.ti-layout-slider:before {
	content: "\e6ef";
}
.ti-layout-slider-alt:before {
	content: "\e6f0";
}
.ti-layout-sidebar-right:before {
	content: "\e6f1";
}
.ti-layout-sidebar-none:before {
	content: "\e6f2";
}
.ti-layout-sidebar-left:before {
	content: "\e6f3";
}
.ti-layout-placeholder:before {
	content: "\e6f4";
}
.ti-layout-menu:before {
	content: "\e6f5";
}
.ti-layout-menu-v:before {
	content: "\e6f6";
}
.ti-layout-menu-separated:before {
	content: "\e6f7";
}
.ti-layout-menu-full:before {
	content: "\e6f8";
}
.ti-layout-media-right-alt:before {
	content: "\e6f9";
}
.ti-layout-media-right:before {
	content: "\e6fa";
}
.ti-layout-media-overlay:before {
	content: "\e6fb";
}
.ti-layout-media-overlay-alt:before {
	content: "\e6fc";
}
.ti-layout-media-overlay-alt-2:before {
	content: "\e6fd";
}
.ti-layout-media-left-alt:before {
	content: "\e6fe";
}
.ti-layout-media-left:before {
	content: "\e6ff";
}
.ti-layout-media-center-alt:before {
	content: "\e700";
}
.ti-layout-media-center:before {
	content: "\e701";
}
.ti-layout-list-thumb:before {
	content: "\e702";
}
.ti-layout-list-thumb-alt:before {
	content: "\e703";
}
.ti-layout-list-post:before {
	content: "\e704";
}
.ti-layout-list-large-image:before {
	content: "\e705";
}
.ti-layout-line-solid:before {
	content: "\e706";
}
.ti-layout-grid4:before {
	content: "\e707";
}
.ti-layout-grid3:before {
	content: "\e708";
}
.ti-layout-grid2:before {
	content: "\e709";
}
.ti-layout-grid2-thumb:before {
	content: "\e70a";
}
.ti-layout-cta-right:before {
	content: "\e70b";
}
.ti-layout-cta-left:before {
	content: "\e70c";
}
.ti-layout-cta-center:before {
	content: "\e70d";
}
.ti-layout-cta-btn-right:before {
	content: "\e70e";
}
.ti-layout-cta-btn-left:before {
	content: "\e70f";
}
.ti-layout-column4:before {
	content: "\e710";
}
.ti-layout-column3:before {
	content: "\e711";
}
.ti-layout-column2:before {
	content: "\e712";
}
.ti-layout-accordion-separated:before {
	content: "\e713";
}
.ti-layout-accordion-merged:before {
	content: "\e714";
}
.ti-layout-accordion-list:before {
	content: "\e715";
}
.ti-ink-pen:before {
	content: "\e716";
}
.ti-info-alt:before {
	content: "\e717";
}
.ti-help-alt:before {
	content: "\e718";
}
.ti-headphone-alt:before {
	content: "\e719";
}
.ti-hand-point-up:before {
	content: "\e71a";
}
.ti-hand-point-right:before {
	content: "\e71b";
}
.ti-hand-point-left:before {
	content: "\e71c";
}
.ti-hand-point-down:before {
	content: "\e71d";
}
.ti-gallery:before {
	content: "\e71e";
}
.ti-face-smile:before {
	content: "\e71f";
}
.ti-face-sad:before {
	content: "\e720";
}
.ti-credit-card:before {
	content: "\e721";
}
.ti-control-skip-forward:before {
	content: "\e722";
}
.ti-control-skip-backward:before {
	content: "\e723";
}
.ti-control-record:before {
	content: "\e724";
}
.ti-control-eject:before {
	content: "\e725";
}
.ti-comments-smiley:before {
	content: "\e726";
}
.ti-brush-alt:before {
	content: "\e727";
}
.ti-youtube:before {
	content: "\e728";
}
.ti-vimeo:before {
	content: "\e729";
}
.ti-twitter:before {
	content: "\e72a";
}
.ti-time:before {
	content: "\e72b";
}
.ti-tumblr:before {
	content: "\e72c";
}
.ti-skype:before {
	content: "\e72d";
}
.ti-share:before {
	content: "\e72e";
}
.ti-share-alt:before {
	content: "\e72f";
}
.ti-rocket:before {
	content: "\e730";
}
.ti-pinterest:before {
	content: "\e731";
}
.ti-new-window:before {
	content: "\e732";
}
.ti-microsoft:before {
	content: "\e733";
}
.ti-list-ol:before {
	content: "\e734";
}
.ti-linkedin:before {
	content: "\e735";
}
.ti-layout-sidebar-2:before {
	content: "\e736";
}
.ti-layout-grid4-alt:before {
	content: "\e737";
}
.ti-layout-grid3-alt:before {
	content: "\e738";
}
.ti-layout-grid2-alt:before {
	content: "\e739";
}
.ti-layout-column4-alt:before {
	content: "\e73a";
}
.ti-layout-column3-alt:before {
	content: "\e73b";
}
.ti-layout-column2-alt:before {
	content: "\e73c";
}
.ti-instagram:before {
	content: "\e73d";
}
.ti-google:before {
	content: "\e73e";
}
.ti-github:before {
	content: "\e73f";
}
.ti-flickr:before {
	content: "\e740";
}
.ti-facebook:before {
	content: "\e741";
}
.ti-dropbox:before {
	content: "\e742";
}
.ti-dribbble:before {
	content: "\e743";
}
.ti-apple:before {
	content: "\e744";
}
.ti-android:before {
	content: "\e745";
}
.ti-save:before {
	content: "\e746";
}
.ti-save-alt:before {
	content: "\e747";
}
.ti-yahoo:before {
	content: "\e748";
}
.ti-wordpress:before {
	content: "\e749";
}
.ti-vimeo-alt:before {
	content: "\e74a";
}
.ti-twitter-alt:before {
	content: "\e74b";
}
.ti-tumblr-alt:before {
	content: "\e74c";
}
.ti-trello:before {
	content: "\e74d";
}
.ti-stack-overflow:before {
	content: "\e74e";
}
.ti-soundcloud:before {
	content: "\e74f";
}
.ti-sharethis:before {
	content: "\e750";
}
.ti-sharethis-alt:before {
	content: "\e751";
}
.ti-reddit:before {
	content: "\e752";
}
.ti-pinterest-alt:before {
	content: "\e753";
}
.ti-microsoft-alt:before {
	content: "\e754";
}
.ti-linux:before {
	content: "\e755";
}
.ti-jsfiddle:before {
	content: "\e756";
}
.ti-joomla:before {
	content: "\e757";
}
.ti-html5:before {
	content: "\e758";
}
.ti-flickr-alt:before {
	content: "\e759";
}
.ti-email:before {
	content: "\e75a";
}
.ti-drupal:before {
	content: "\e75b";
}
.ti-dropbox-alt:before {
	content: "\e75c";
}
.ti-css3:before {
	content: "\e75d";
}
.ti-rss:before {
	content: "\e75e";
}
.ti-rss-alt:before {
	content: "\e75f";
}

body {
  margin: 0;
  padding: 0;
  background-color: #ebebeb;
  font-family: 'Nunito', sans-serif !important;
  font-size: 14px !important;
}
.form-membership {
  background: url(../../static/media/bg.78f5154d.png) white;
  background-attachment: fixed;
  background-size: cover;
  padding: 3rem 0;
  height: 100vh;
}

.form-membership .form-wrapper {
  background-color: white;
  padding: 3rem;
  border-radius: 5px;
  width: 430px;
  margin: 50px auto;
  text-align: center;
}

.form-membership .form-wrapper .logo {
  background: url(../../static/media/ZellaTech-LogoSMS-.53d0ca9c.png) no-repeat;
  background-size: 100%;
  width: 85px;
  /* -webkit-border-radius: 50%;
  -moz-border-radius: 50%; */
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  position: absolute;
  right: 0;
  left: 0;
  top: 45px;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .form-membership .form-wrapper .logo {
    background: url(../../static/media/ZellaTech-LogoSMS-.53d0ca9c.png) no-repeat;
    background-size: 100%;
    width: 55px;
    /* -webkit-border-radius: 50%;
    -moz-border-radius: 50%; */
    /* border-radius: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    position: absolute;
    right: 0;
    left: 0;
    top: 45px;
    margin: auto;
  }
}
.form-membership .form-wrapper h5 {
  text-align: center;
  margin-bottom: 2rem;
}

.form-membership .form-wrapper form .form-control {
  margin-bottom: 1.5rem;
}

.form-membership .form-wrapper hr {
  margin: 2rem 0;
}

@media screen and (max-width: 576px) {
  .form-membership {
    padding: 20px;
  }

  .form-membership .form-wrapper {
    padding-right: 20px;
    padding-left: 20px;
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.custom-control-label {
  line-height: 25px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3db16b;
  border-color: #3db16b;
}

.dark {
  background-color: #272935;
  color: rgba(255, 255, 255, 0.75);
}

.dark .text-muted {
  color: rgba(255, 255, 255, 0.4) !important;
}

.dark nav.navigation {
  background-color: #464852;
}

.dark nav.navigation ul li span {
  color: rgba(255, 255, 255, 0.7);
}

.dark nav.navigation ul li span:hover,
.dark nav.navigation ul li span:focus {
  background-color: #5a5c66;
}

.dark .layout .content .sidebar-group .sidebar {
  background-color: #464852;
}

.dark
  .layout
  .content
  .sidebar-group
  .sidebar
  .list-group-item
  .users-list-body
  p {
  color: rgba(255, 255, 255, 0.4);
}

.dark
  .layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text'] {
  background-color: #5a5c66;
  color: rgba(255, 255, 255, 0.7);
}

.dark
  .layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text']::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark
  .layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text']::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark
  .layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text']::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark
  .layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text']:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark
  .layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text']::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark .layout .content .chat .chat-header {
  border-bottom-color: #32343e;
}

.dark
  .layout
  .content
  .chat
  .chat-body
  .messages
  .message-item
  .message-content {
  background-color: #464852;
}

.dark
  .layout
  .content
  .chat
  .chat-body
  .messages
  .message-item.outgoing-message
  .message-content {
  background-color: rgba(61, 177, 107, 0.3);
}

.dark .layout .content .chat .chat-footer {
  border-top-color: #32343e;
}

.dark .layout .content .chat .chat-footer .chat-footer-form {
  background-color: #464852;
}

.dark .layout .content .chat .chat-footer .chat-footer-form input[type='text'] {
  color: rgba(255, 255, 255, 0.7);
}

.dark
  .layout
  .content
  .chat
  .chat-footer
  .chat-footer-form
  input[type='text']::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark
  .layout
  .content
  .chat
  .chat-footer
  .chat-footer-form
  input[type='text']::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark
  .layout
  .content
  .chat
  .chat-footer
  .chat-footer-form
  input[type='text']::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark
  .layout
  .content
  .chat
  .chat-footer
  .chat-footer-form
  input[type='text']:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark
  .layout
  .content
  .chat
  .chat-footer
  .chat-footer-form
  input[type='text']::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark.rtl
  .layout
  .content
  .sidebar-group
  .sidebar
  .list-group-item
  .users-list-body
  .users-list-action {
  box-shadow: 8px 1px 10px 5px #464852 !important;
}

.dark .list-group-item {
  border-color: #5a5c66;
}

.dark .dropdown-menu {
  background-color: #5a5c66;
  border-color: transparent;
}

.dark .dropdown-menu .dropdown-item {
  color: rgba(255, 255, 255, 0.7);
}

.dark .dropdown-menu .dropdown-item:focus,
.dark .dropdown-menu .dropdown-item:hover,
.dark .dropdown-menu .dropdown-item:active,
.dark .dropdown-menu .dropdown-item.active {
  background-color: #464852;
}

.dark input[type='text'],
.dark textarea,
.dark select {
  background-color: #5a5c66;
  color: rgba(255, 255, 255, 0.7);
  border-color: #6e707a;
}

.dark input[type='text']:focus,
.dark textarea:focus,
.dark select:focus {
  color: rgba(255, 255, 255, 0.7);
  background-color: #5a5c66;
  border-color: #aaacb6;
}

.dark input[type='text']::-webkit-input-placeholder,
.dark textarea::-webkit-input-placeholder,
.dark select::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark input[type='text']::-moz-placeholder,
.dark textarea::-moz-placeholder,
.dark select::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark input[type='text']::-ms-input-placeholder,
.dark textarea::-ms-input-placeholder,
.dark select::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark input[type='text']::-webkit-input-placeholder, .dark textarea::-webkit-input-placeholder, .dark select::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark input[type='text']::-moz-placeholder, .dark textarea::-moz-placeholder, .dark select::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark input[type='text']:-ms-input-placeholder, .dark textarea:-ms-input-placeholder, .dark select:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark input[type='text']::-ms-input-placeholder, .dark textarea::-ms-input-placeholder, .dark select::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark input[type='text']::placeholder,
.dark textarea::placeholder,
.dark select::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark .custom-control-label::before {
  background-color: #5a5c66;
  border-color: #6e707a;
}

.dark .input-group-text {
  background-color: #464852;
  color: rgba(255, 255, 255, 0.7);
  border-color: #6e707a;
}

.dark .custom-file-label {
  background-color: #5a5c66;
  border-color: #6e707a;
  color: rgba(255, 255, 255, 0.7);
}

.dark .custom-file-label:after {
  background-color: #464852;
  color: rgba(255, 255, 255, 0.7);
}

.dark .btn.btn-light {
  background-color: #5a5c66;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}

.dark .btn.btn-light:focus,
.dark .btn.btn-light:hover {
  border-color: transparent !important;
  background-color: #5a5c66 !important;
  color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0 0 0 0.2rem rgba(90, 92, 102, 0.6) !important;
}

.dark figure.avatar.avatar-state-danger:before,
.dark figure.avatar.avatar-state-dark:before,
.dark figure.avatar.avatar-state-info:before,
.dark figure.avatar.avatar-state-light:before,
.dark figure.avatar.avatar-state-primary:before,
.dark figure.avatar.avatar-state-secondary:before,
.dark figure.avatar.avatar-state-success:before,
.dark figure.avatar.avatar-state-warning:before {
  border-color: #464852;
}

.dark .layout .content .sidebar-group .sidebar .list-group-item:first-child {
  border-top: 1px solid #5a5c66 !important;
}

.dark .layout .content .sidebar-group .sidebar .list-group-item:last-child {
  border-bottom: 1px solid #5a5c66 !important;
}

.dark .nav-tabs .nav-link.active {
  color: #fff;
}

.dark-modal .calendar {
  width: 100%;
  background-position: 95% 50%;
  background-color: white;
  position: relative;
  background-color: #5a5c66;
  color: rgba(255, 255, 255, 0.7);
}

.dark-modal .calendar:focus {
  color: rgba(255, 255, 255, 0.7);
  background-color: #5a5c66;
  border-color: #aaacb6;
}

.dark-modal .calendar::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.light {
  background-color: #ebebeb;
  color: #212529;
}

.light nav.navigation {
  background-color: #fff;
}

.light nav.navigation ul li span {
  color: #828282;
}

.light nav.navigation ul li span:hover,
.light nav.navigation ul li span:focus {
  color: #3db16b;
}

.light .layout .content .sidebar-group .sidebar {
  background-color: #fff;
}

.light
  .layout
  .content
  .sidebar-group
  .sidebar
  .list-group-item
  .users-list-body
  p {
  color: #212529;
}

.light
  .layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text']::-webkit-input-placeholder {
  color: #828282;
}

.light
  .layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text']::-moz-placeholder {
  color: #828282;
}

.light
  .layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text']::-ms-input-placeholder {
  color: #828282;
}

.light
  .layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text']:-ms-input-placeholder {
  color: #828282;
}

.light
  .layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text']::placeholder {
  color: #828282;
}

.light .layout .content .chat .chat-header {
  border-bottom-color: #e1e1e4;
}

.light
  .layout
  .content
  .chat
  .chat-body
  .messages
  .message-item
  .message-content {
  background-color: #fff;
}

.light
  .layout
  .content
  .chat
  .chat-body
  .messages
  .message-item.outgoing-message
  .message-content {
  background-color: #cdcdcd;
}

.light .layout .content .chat .chat-footer {
  border-top-color: #e1e1e1;
}

.light .layout .content .chat .chat-footer .chat-footer-form {
  background-color: #ffffff;
}

.light
  .layout
  .content
  .chat
  .chat-footer
  .chat-footer-form
  input[type='text'] {
  color: #212529;
}

.light
  .layout
  .content
  .chat
  .chat-footer
  .chat-footer-form
  input[type='text']::-webkit-input-placeholder {
  color: #828282;
}

.light
  .layout
  .content
  .chat
  .chat-footer
  .chat-footer-form
  input[type='text']::-moz-placeholder {
  color: #828282;
}

.light
  .layout
  .content
  .chat
  .chat-footer
  .chat-footer-form
  input[type='text']::-ms-input-placeholder {
  color: #828282;
}

.light
  .layout
  .content
  .chat
  .chat-footer
  .chat-footer-form
  input[type='text']:-ms-input-placeholder {
  color: #828282;
}

.light
  .layout
  .content
  .chat
  .chat-footer
  .chat-footer-form
  input[type='text']::placeholder {
  color: #828282;
}

.light
  .rtl
  .layout
  .content
  .sidebar-group
  .sidebar
  .list-group-item
  .users-list-body
  .users-list-action {
  box-shadow: 8px 1px 10px 5px #464852 !important;
}

.light .list-group-item {
  border-color: #ebebeb;
}

.light .dropdown-menu {
  background-color: #ffffff;
  border-color: transparent;
}

.light .dropdown-menu .dropdown-item {
  color: #212529;
}

.light .dropdown-menu .dropdown-item:focus,
.light .dropdown-menu .dropdown-item:hover,
.light .dropdown-menu .dropdown-item:active,
.light .dropdown-menu .dropdown-item.active {
  background-color: #e6e6e6;
}

.light input[type='text'],
.light textarea,
.light select {
  border-color: #6e707a;
}

.light input[type='text']:focus,
.light textarea:focus,
.light select:focus {
  border-color: #aaacb6;
}

.light input[type='text']::-webkit-input-placeholder,
.light textarea::-webkit-input-placeholder,
.light select::-webkit-input-placeholder {
  color: #828282;
}

.light input[type='text']::-moz-placeholder,
.light textarea::-moz-placeholder,
.light select::-moz-placeholder {
  color: #828282;
}

.light input[type='text']::-ms-input-placeholder,
.light textarea::-ms-input-placeholder,
.light select::-ms-input-placeholder {
  color: #828282;
}

.light input[type='text']::-webkit-input-placeholder, .light textarea::-webkit-input-placeholder, .light select::-webkit-input-placeholder {
  color: #828282;
}

.light input[type='text']::-moz-placeholder, .light textarea::-moz-placeholder, .light select::-moz-placeholder {
  color: #828282;
}

.light input[type='text']:-ms-input-placeholder, .light textarea:-ms-input-placeholder, .light select:-ms-input-placeholder {
  color: #828282;
}

.light input[type='text']::-ms-input-placeholder, .light textarea::-ms-input-placeholder, .light select::-ms-input-placeholder {
  color: #828282;
}

.light input[type='text']::placeholder,
.light textarea::placeholder,
.light select::placeholder {
  color: #828282;
}

.light .custom-control-label::before {
  background-color: #5a5c66;
  border-color: #6e707a;
}

.light .input-group-text {
  background-color: #464852;
  border-color: #6e707a;
}

.nav-tabs .nav-link.active {
  border-color: #5a5c66;
  border-bottom-color: #464852;
  background-color: #464852;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #5a5c66;
  border-bottom-color: #464852;
}

.nav-tabs {
  border-bottom-color: #5a5c66;
}

.light .custom-file-label {
  background-color: #5a5c66;
  border-color: #6e707a;
}

.light .custom-file-label:after {
  background-color: #464852;
}

.light .btn.btn-light {
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.light .btn.btn-light:focus,
.light .btn.btn-light:hover {
  border-color: transparent !important;
  background-color: #c8c8c8 !important;
}

.light figure.avatar.avatar-state-danger:before,
.light figure.avatar.avatar-state-light :before,
.light figure.avatar.avatar-state-info:before,
.light figure.avatar.avatar-state-light:before,
.light figure.avatar.avatar-state-primary:before,
.light figure.avatar.avatar-state-secondary:before,
.light figure.avatar.avatar-state-success:before,
.light figure.avatar.avatar-state-warning:before {
  border-color: #e6e6e6;
}

.light .layout .content .sidebar-group .sidebar .list-group-item:first-child {
  border-top: 1px solid #ebebeb !important;
}

.light .layout .content .sidebar-group .sidebar .list-group-item:last-child {
  border-bottom: 1px solid #ebebeb !important;
}

.light .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.light .nav-tabs .nav-link:hover,
.light .nav-tabs .nav-link:focus {
  border-color: #dee2e6;
  border-bottom-color: #dee2e6;
}

.light .nav-tabs {
  border-bottom-color: #dee2e6;
}

.layout
  .content
  .sidebar-group
  .sidebar
  .list-group-item
  .users-list-body
  .users-list-action {
  display: flex;
  align-items: center;
}

.layout {
  /* height: 100vh; */
  height: 100% !important;
  /* height: -webkit-calc(var(--vh, 1vh) * 100); */
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
}

.layout .content {
  padding: 30px;
  display: flex;
  flex: 1 1;
}

.layout .content .sidebar-group {
  display: flex;
  flex-direction: column;
}

.layout .content .sidebar-group .sidebar {
  margin-right: 30px;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  width: 330px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.layout .content .sidebar-group .sidebar:not(.active) {
  display: none;
}

.layout .content .sidebar-group .sidebar .nav-tabs {
  padding-left: 5px;
  display: flex;
}

.layout .content .sidebar-group .sidebar > header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 18px;
  height: 80px;
  font-weight: 700;
  border-radius: 0;
}

.layout .content .sidebar-group .sidebar > header > span {
  font-size: 22px;
}

.layout .content .sidebar-group .sidebar > button {
  position: absolute;
  display: none;
}

.layout .content .sidebar-group .sidebar > header ul {
  margin-bottom: 0;
}

.layout .content .sidebar-group .sidebar > header ul li {
  margin-bottom: 0;
}

.layout .content .sidebar-group .sidebar .sidebar-form {
  padding: 10px 20px;
}

.layout .content .sidebar-group .sidebar .sidebar-form input[type='text'] {
  border-radius: 5px;
  border: none;
  height: auto;
  padding: 10px 15px;
  background-color: #ebebeb;
}

.layout
  .content
  .sidebar-group
  .sidebar
  .sidebar-form
  input[type='text']:focus {
  box-shadow: none;
}

.layout .content .sidebar-group .sidebar .sidebar-body {
  flex: 1 1;
  overflow: hidden;
  height: 100%;
}

.layout .content .sidebar-group .sidebar .list-group-item {
  padding: 15px 20px;
  display: flex;
  cursor: pointer;
}
.content_body{
  background: #F2F2F3;
}
.layout .content .sidebar-group .sidebar .list-group-item:before {
  content: '';
  position: absolute;
  display: block;
  width: 5px;
  /* background-color: #3db16b; */
  background-color: #1E81FF;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
}

.layout .content .sidebar-group .sidebar .list-group-item.open-chat:before {
  opacity: 1;
}

.layout
  .content
  .sidebar-group
  .sidebar
  .list-group-item.open-chat
  .users-list-body
  p {
  font-weight: 600;
  color: #646464;
}

.layout .content .sidebar-group .sidebar .list-group-item figure {
  margin-right: 1rem;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body {
  flex: 1 1;
  position: relative;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body h5 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.2rem;
  line-height: 1;
}
.layout .content .sidebar-group .sidebar .list-group-item .users-list-body h6 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  font-weight: 700;
  margin-top: 0.5rem;
  font-style: italic;
  color: #8e8c8c;
}
.layout .content .sidebar-group .sidebar .list-group-item .users-list-body p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
  color: #969696;
}

.layout
  .content
  .sidebar-group
  .sidebar
  .list-group-item
  .users-list-body
  .users-list-action {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0 5px;
}

.layout
  .content
  .sidebar-group
  .sidebar
  .list-group-item
  .users-list-body
  .users-list-action
  [data-toggle='dropdown']
  i {
  font-size: 18px;
}

.layout
  .content
  .sidebar-group
  .sidebar
  .list-group-item
  .users-list-body
  .users-list-action
  .new-message-count {
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-size: 13px;
  height: 23px;
  background-color: #3db16b;
  color: white;
  border-radius: 50%;
}

.layout .list-group-item .users-list-body .users-list-action .action-toggle {
  z-index: 10;
  display: none;
}

.layout
  .list-group-item:hover
  .users-list-body
  .users-list-action
  .action-toggle {
  display: block;
}

.layout
  .list-group-item:hover
  .users-list-body
  .users-list-action
  .action-toggle
  .dropdown
  > button {
  color: #3db16b !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.layout .content .chat {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.layout .content .chat .chat-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e1e1e1;
  padding-bottom: 20px;
}

.layout .content .chat .chat-header .chat-header-user {
  display: flex;
  align-items: center;
}

.layout .content .chat .chat-header .chat-header-user figure {
  margin-right: 1rem;
}

.layout .content .chat .chat-header .chat-header-user h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
}

.layout .content .chat .chat-header .chat-header-action {
  display: flex;
  align-items: center;
}

.layout .content .chat .chat-header .chat-header-action ul {
  margin-bottom: 0;
}

.layout .content .chat .chat-header .chat-header-action ul > li > span {
  font-size: 16px;
}

.layout .content .chat .chat-body {
  flex: 1 1;
  margin: 10px 0;
}

.layout .content .chat .chat-body:not(.no-message) {
  overflow: hidden;
}

.layout .content .chat .chat-body.no-message {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
}

.layout .content .chat .chat-body.no-message .no-message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.layout .content .chat .chat-body.no-message .no-message-container i {
  font-size: 7em;
  opacity: 0.15;
  margin-bottom: 15px;
}

.layout .content .chat .chat-body.no-message .no-message-container p {
  font-size: 16px;
  color: #828282;
}

.layout .content .chat .chat-body .messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px 0 10px;
}

.layout .content .chat .chat-body .messages .message-item {
  max-width: 75%;
  margin-bottom: 20px;
}

.layout .content .chat .chat-body .messages .message-item .message-content {
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.layout
  .content
  .chat
  .chat-body
  .messages
  .message-item
  .message-content.message-file {
  display: flex;
  align-items: center;
  word-break: break-word;
}

.layout
  .content
  .chat
  .chat-body
  .messages
  .message-item
  .message-content.message-file
  .file-icon {
  font-size: 24px;
  margin-right: 1rem;
  color: #828282;
}

.layout
  .content
  .chat
  .chat-body
  .messages
  .message-item
  .message-content.message-file
  ul {
  margin-top: 3px;
  margin-bottom: 0;
}

.layout
  .content
  .chat
  .chat-body
  .messages
  .message-item
  .message-content.message-file
  ul
  li.list-inline-item {
  margin-left: 0;
  margin-right: 0.5rem;
}

.layout .content .chat .chat-body .messages .message-item .message-action {
  color: #828282;
  margin-top: 5px;
  font-style: italic;
  font-size: 12px;
}

.layout
  .content
  .chat
  .chat-body
  .messages
  .message-item
  .message-action
  .ti-double-check:before,
.layout
  .content
  .chat
  .chat-body
  .messages
  .message-item
  .message-action
  .ti-double-check:after {
  content: '\e64c';
  font-family: 'themify';
}

.layout
  .content
  .chat
  .chat-body
  .messages
  .message-item
  .message-action
  .ti-double-check:after {
  margin-left: -4px;
}

.layout .content .chat .chat-body .messages .message-item.outgoing-message {
  margin-left: auto;
}

.layout
  .content
  .chat
  .chat-body
  .messages
  .message-item.outgoing-message
  .message-content {
  background-color: #cdcdcd;
}

.layout
  .content
  .chat
  .chat-body
  .messages
  .message-item.outgoing-message
  .message-action {
  text-align: right;
}

.layout .content .chat .chat-footer {
  border-top: 2px solid #e1e1e1;
  padding-top: 15px;
}

.layout .content .chat .chat-footer .chat-footer-form {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  background-color: white;
}

.layout .content .chat .chat-footer .chat-footer-form .form-buttons {
  display: flex;
}

.layout .content .chat .chat-footer .chat-footer-form .form-buttons .btn {
  margin-left: 0.5rem;
}

.layout .content .chat .chat-footer .chat-footer-form input[type='text'] {
  border: none;
  background-color: inherit;
}

.layout .content .chat .chat-footer .chat-footer-form input[type='text']:focus {
  box-shadow: none;
}

nav.navigation {
  background: #222225;
  position: relative;
  float: left;
  /* height: 100%; */

}

nav.navigation .nav-group {
  width: 250px;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

.side-nav.active {
  background: #101012;
  border-left: 3px solid #1E81FF;
  cursor: pointer;
}

.side-nav.active i{
  /* color: #F4F4F5 !important; */
  color: #1E81FF !important;
 
}
.side-nav span{
font-size: 1rem;
}
nav.navigation ul li span.active {
  color: #1E81FF !important;
  font-size: 1rem;
}

.shadow{
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
}
nav.navigation ul {
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

nav.navigation ul li {
  list-style: none;

}


nav.navigation ul li > span {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  padding-left: 1.25rem;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  font-weight: 300;
  color: #F4F4F5;
  border-radius: 0.2rem;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

nav.navigation ul li span.notifiy_badge:before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background-color: #ff7373;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
}

nav.navigation ul li span.active {
  /* color: #3db16b; */
  color:#1E81FF
}

nav.navigation ul li span.logo {
  margin-bottom: 1rem;
  /* background-color: #3db16b; */
  background: url(../../static/media/Zella-Logo-Transparent.18f2bd7c.png) no-repeat;
  background-size: 100%;
  height: 80px;
  width: 60px;
  margin: auto;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  nav.navigation .nav-group {
    width: 70px !important;
  }


  nav.navigation ul li > span > span {
    display: none !important;
  }

  .sidbar-collapse-icon-container {
    display: none;
  }



  nav.navigation ul li span.logo {
    margin-bottom: 1rem;   
    background: url(../../static/media/Zella-Logo-Transparent.18f2bd7c.png) no-repeat;
    background-size: 100%; 
    height: 65px;
    width: 50px;
    margin: auto;
    margin-top: 10px;
  }
}
nav.navigation ul li span.logo:hover,
nav.navigation ul li span.logo:focus {
  /* background-color: #cccdce; */
}

nav.navigation ul li span:hover,
nav.navigation ul li span:focus {
  /* color: #3db16b; */
  /* color: #3db16b; */
  cursor: pointer;
}

nav.navigation ul li span i {
  width: 16px;
  height: 16px;
  margin-right: 15px;
  margin-left: 5px;
}

/* nav.navigation ul li.brackets {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
} */

@media screen and (min-width: 993px) {
  /* .sidebar-group {
    opacity: 1;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    visibility: visible;
  }

  .sidebar-group.open {
    display: none !important;
    transition: opacity 1s ease-out;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;
  } */
  .nav-menu {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .nav-menu {
    display: block;
  }

  .sidebar-group {
    position: fixed;
    right: -50%;
    top: 0;
    bottom: 0;
    width: 40%;
    opacity: 0;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    box-shadow: 25px 0px 30px 5px black;
    visibility: hidden;
  }

  .sidebar-group.open {
    z-index: 1;
    right: 0;
    opacity: 1;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    visibility: visible;
  }

  .sidebar-group .sidebar {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 0 !important;
  }

  .sidebar-group .sidebar > button {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 17px;
    opacity: 1 !important;
  }

  .sidebar-group.mobile-open {
    opacity: 1;
    right: 0;
    visibility: visible;
  }
}

@media screen and (max-width: 768px) {
  .sidebar-group {
    width: 60%;
  }

  .sidebar-group .sidebar .list-group-item .users-list-body h5 {
    font-size: 18px !important;
  }

  .layout .content {
    padding: 20px !important;
  }

  .layout .content .chat .chat-header {
    display: block !important;
    padding-bottom: 15px !important;
  }

  .layout .content .chat .chat-header .chat-header-user h5 {
    font-size: 19px !important;
  }

  .layout .content .chat .chat-header .chat-header-action {
    margin-top: 15px;
  }

  .layout .content .chat .chat-body .messages .message-item {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 576px) {
  .sidebar-group {
    width: 70%;
  }
}

.list-group-item {
  background-color: inherit;
  border-color: #ebebeb;
}

ul.list-inline .list-inline-item {
  margin-bottom: 0.5rem;
}

ul.list-inline .list-inline-item:not(:last-child) {
  margin-right: 0;
}

ul.list-inline:not(.social-links) .list-inline-item {
  margin-left: 0.5rem;
}

ul.list-inline.social-links .list-inline-item {
  margin-right: 0.5rem;
  margin-bottom: 0.6rem;
}

.files ul.list-inline {
  display: flex;
  overflow-x: auto;
}

.files ul.list-inline .list-inline-item {
  margin-left: 0;
  margin-right: 0.5rem;
}

figure.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}

figure.avatar .avatar-title {
  color: rgba(255, 255, 255, 0.8);
  background: #d7d7d7;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 600;
}

figure.avatar > a {
  width: 100%;
  height: 100%;
  display: block;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: #3db16b;
}

figure.avatar > a > img,
figure.avatar > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

figure.avatar.avatar-sm {
  height: 1.5rem;
  width: 1.5rem;
}

figure.avatar.avatar-sm .avatar-title {
  font-size: 14px;
}

figure.avatar.avatar-sm.avatar-state-primary:before,
figure.avatar.avatar-sm.avatar-state-success:before,
figure.avatar.avatar-sm.avatar-state-danger:before,
figure.avatar.avatar-sm.avatar-state-warning:before,
figure.avatar.avatar-sm.avatar-state-info:before,
figure.avatar.avatar-sm.avatar-state-secondary:before,
figure.avatar.avatar-sm.avatar-state-light:before,
figure.avatar.avatar-sm.avatar-state-dark:before {
  width: 0.8rem;
  height: 0.8rem;
}

figure.avatar.avatar-lg {
  height: 4rem;
  width: 4rem;
}

figure.avatar.avatar-lg .avatar-title {
  font-size: 29px;
}

figure.avatar.avatar-lg.avatar-state-primary:before,
figure.avatar.avatar-lg.avatar-state-success:before,
figure.avatar.avatar-lg.avatar-state-danger:before,
figure.avatar.avatar-lg.avatar-state-warning:before,
figure.avatar.avatar-lg.avatar-state-info:before,
figure.avatar.avatar-lg.avatar-state-secondary:before,
figure.avatar.avatar-lg.avatar-state-light:before,
figure.avatar.avatar-lg.avatar-state-dark:before {
  width: 1.2rem;
  height: 1.2rem;
  right: -1px;
}

figure.avatar.avatar-xl {
  height: 6.3rem;
  width: 6.3rem;
}

figure.avatar.avatar-xl .avatar-title {
  font-size: 39px;
}

figure.avatar.avatar-xl.avatar-state-primary:before,
figure.avatar.avatar-xl.avatar-state-success:before,
figure.avatar.avatar-xl.avatar-state-danger:before,
figure.avatar.avatar-xl.avatar-state-warning:before,
figure.avatar.avatar-xl.avatar-state-info:before,
figure.avatar.avatar-xl.avatar-state-secondary:before,
figure.avatar.avatar-xl.avatar-state-light:before,
figure.avatar.avatar-xl.avatar-state-dark:before {
  width: 1.2rem;
  height: 1.2rem;
  top: 2px;
  right: 7px;
}

figure.avatar.avatar-state-primary,
figure.avatar.avatar-state-success,
figure.avatar.avatar-state-danger,
figure.avatar.avatar-state-warning,
figure.avatar.avatar-state-info,
figure.avatar.avatar-state-secondary,
figure.avatar.avatar-state-light,
figure.avatar.avatar-state-dark {
  position: relative;
}

figure.avatar.avatar-state-primary:before,
figure.avatar.avatar-state-success:before,
figure.avatar.avatar-state-danger:before,
figure.avatar.avatar-state-warning:before,
figure.avatar.avatar-state-info:before,
figure.avatar.avatar-state-secondary:before,
figure.avatar.avatar-state-light:before,
figure.avatar.avatar-state-dark:before {
  content: '';
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  top: -3px;
  right: -3px;
  border: 3px solid white;
}

figure.avatar.avatar-state-primary:before {
  background: #3db16b;
}

figure.avatar.avatar-state-success:before {
  background: #0abb87;
}

figure.avatar.avatar-state-danger:before {
  background: #ea4141;
}

figure.avatar.avatar-state-warning:before {
  background: #ff9d36;
}

figure.avatar.avatar-state-info:before {
  background: #00bcd4;
}

figure.avatar.avatar-state-secondary:before {
  background: dimgray;
}

figure.avatar.avatar-state-light:before {
  background: #e6e6e6;
}

figure.avatar.avatar-state-dark:before {
  background: #3a3f51;
}

.tab-content {
  margin: 15px 0;
}

.tab-content .form-item {
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
}

.tab-title {
  font-size: 16px;
}

.input-message {
  width: 100%;
  min-height: 50px;
  max-height: 100px;
  overflow: auto;
  padding: 9px;
  resize: none;
}

.sender {
  color: #828282;
  margin-bottom: 15px;
  font-size: 15px;
}

.sender span:hover {
  color: #e6e6e6;
  cursor: pointer;
}

.chat-footer-form i {
  font-size: 18px;
}

.chat-footer-form i:hover {
  font-size: 20px;
}

input[type='file'] {
  display: none;
}

.chat-image-upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-image-upload label {
  margin-bottom: 0px;
}

.message-content .img-view {
  width: 200px;
  height: 200px;
}

.converstaion {
  background-color: #5a5c66;
  border-color: #aaacb6;
}

.calendar {
  width: 100%;
  background-position: 95% 50%;
}

.printer-modal {
  height: 290px;
}
.modal-header{
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
}
.unread-message-count {
  width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-size: 13px;
  height: 23px;
  background-color: #3db16b;
  color: white;
  border-radius: 50%;
}

.btn.call-forward-light {
  background: #e6e6e6;
  border-color: #d4d4de;
  border-radius: 0px;
}
.btn.call-forward-light:focus,
.btn.call-forward-light:hover {
  border-color: transparent !important;
  background-color: #c8c8c8 !important;
}

.btn.call-forward-dark {
  background: #5a5c66;
  border-color: #5a5c66;
}
.btn.call-forward-dark:focus,
.btn.call-forward-dark:hover {
  border-color: transparent !important;
  background-color: #e6e6e6 !important;
}
.call-forward-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-container {
  margin: 0px 24px;
  text-align: left;
}

.history {
  display: inline-block;
  width: 100%;
  position: relative;
}

.history-search {
  position: relative;
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 26px;
}

.history-search label {
  display: block;
  text-align: left;
  font-size: 14px;
  color: grey;
}

.state-date {
  margin-right: 8px;
  width: 140px;
}

.calendar1 {
  width: 130px;
  background-position: 95% 50%;
  background-color: white;
  position: relative;
}

.end-date {
  margin-right: 8px;
  width: 140px;
}

.history-search-button {
  position: absolute;
  bottom: 0px;
}

.call-search-text {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 15rem !important;
  background-position: 95% 50%;
  background-color: white;
}

.bottom-nav {
  float: right;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 14px;
}

.bottom-nav button {
  font-size: 0.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.1rem;
  color: grey;
  background: #f7f7f7;
  border: 1px solid #cdcdd0;
  margin-right: 0;
  margin: 0;
}

.bottom-nav button:first-of-type,
.bottom-nav button:nth-of-type(2) {
  color: lightgrey;
}

.bottom-nav button:nth-of-type(3) {
  background: #d3d3d6;
  color: black;
}

.bottom-nav select {
  height: 2rem;
  border: 1px solid grey;
  padding: 1rem 3rem 1rem 0;
  margin: 0 10px;
  color: black;
  font-size: 0.7rem;
  font-weight: 400;
}

.page-num {
  font-size: 0.7rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.button-disable {
  color: grey !important;
}

.button-enable {
  color: #000 !important;
}

.call-history-table {
  border: 0px;
  width: 100%;
  margin-left: 15px;
}

.history-table-title {
  font-size: 12px;
  color: grey;
  margin-bottom: 15px;
  width: 100%;
}

.call-history-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
  margin-top: -1px;
  float: left;
  width: 100%;
  border-top: solid 1px #bcbcc2;
  border-bottom: solid 1px #bcbcc2;
}

.content_body{
  background: #F2F2F3;
}

.history-from {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.call-icon {
  float: left;
}

.calls-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: gray;
}

.incalls-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: #3da00e;
}

.outcalls-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: #3553cc;
}

.missed-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  line-height: inherit;
  vertical-align: middle;
  color: #ff3d24;
}

.name {
  margin-bottom: 0;
  font-size: 16px;
}

.number {
  font-size: 14px;
  color: gray;
}
/*  */
.history {
	background: #F2F2F3;
	display: inline-block;
	width: 100%;
	overflow-y: scroll;
	position: relative;
}

.call-history thead {
	background: #F2F2F3;
}

#call-history td {
	font-size: 16px;
	padding: 0.6rem 0 0.6rem 0 !important;
}
#call-history table {
  border: 0px;
}

#call-history tbody {
  background-color: white;
}

th,
td {
	padding: 1rem 0 1rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th:last-child,
td:last-child {
  border-right: 0;
}
#call-history div,
#faxes div {
  vertical-align: middle;
}
.call-history tbody {
	line-height: 1rem;
	font-size: 1.1rem;
  background-color: white;
}
.recent-calls{
	height: 290px;
	overflow: auto;
}
.call-history tbody > tr {
	border-bottom: 1px solid #CFCFD6;
	border-left: 1px solid #CFCFD6;
	border-right: 1px solid #CFCFD6;
}
.first-child {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.second-child {
	padding-left: 0px !important;
}
.call-icon{
	float: left;
}
.last-child {
	border-bottom: 0px !important;
}
#call-history thead > tr > th:first-of-type {
	padding-left: 13px !important;
}
#call-history thead > tr > th:last-of-type {
	padding-right: 10px !important;
}
#call-history tbody > tr > td:last-of-type {
	border-bottom: 0px !important;
	padding-right:10px !important;
}

#call-history tbody > tr > td:last-of-type img {
	float: right;
}

.history-search {
	position: relative;
	margin-top: 32px;
	display: flex;
	justify-content: flex-start;
	margin-bottom: 26px;
}

.calendar1 {
	width: 130px;
	/* background: url('../../assets/images/calendar.png') no-repeat scroll; */
	background-position: 95% 50%;
	background-color: white;
	position: relative;
}
.state-date{
	margin-right: 8px;
}
.end-date{
	margin-right: 8px;
}
.react-date-picker__wrapper {
	border: 1px solid #CDCDD0;
	background-color: white;
	padding: 4px;
	margin-right: 10px;
}

.history-search label {
	display: block;
	text-align: left;
	font-size: 14px;
	color: grey;
}

.bottom-nav {
	float: right;
	margin-bottom: 1rem;
	margin-top: 1rem;
	font-size: 12px;
	display: flex;
	align-items: center;
}

.bottom-nav select {
	width: 3rem;
	height: 25px;
}
.bottom-nav label {
	margin: 0 10px;
}
/* .call-search-text{
	position: absolute;
	bottom: 0px;
	right:0px;
	width: 15rem !important;
	background: url('../../assets/images/magnifying-glass.png') no-repeat scroll;
	background-position: 95% 50%;
	background-color: white;
} */
.history-from{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.history-search-button {
	position: absolute;
	bottom: 0px;
	border-radius: 0.2rem;
	color: grey;
	background: #F7F7F7;
	border: 1px solid #CDCDD0;
	padding: 6px 13px;
}
.call-history-table{
  border: 0px;
	width: 100%;
}
.history-table-title{
	font-size: 12px;
	color: grey;
	margin: 0px 0px 15px 0px;
}
.call-history-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
  margin-top: -1px;
  float: left;
  width:100%;
  border-top: solid 1px #BCBCC2;
  border-bottom: solid 1px #BCBCC2;
}
.call-history-row:hover, .call-history-row-active {
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
	background-color: #ffffff;
	border-radius: 3px;
}
.avatar{
	width: 11%;
	margin-right: 12px;
	position: relative;
}
.img-avatar{
	width: 2.5rem;
	border-radius: 50%;

}
.call-view-all {
  color: #1E81FF;
	cursor: pointer;
	font-size: 16px;
}
.call-view-all :hover {
	text-decoration: underline;
	color: #1E81FF;
}
.missed-content{
	background-color: #FF3D24;
	padding: 4px 11px;
	border-radius: 50%;
	color: #222224;
	font-weight: bold;
	text-align: center;
}
.missed-call-icon{
	width: 16px;
	height: 16px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
	color: #ffffff;
}
.calls-icon{
	width: 16px;
	height: 16px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
	color: gray;
}
.missed-icon{
	width: 16px;
	height: 16px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
	color: #FF3D24;
}

/*  */.main-container {
	margin: 0px 24px;
	text-align: left;
}

.voicemails {
	display: inline-block;
	width: 100%;
	position: relative;
}

.voicemails td {
	border-bottom: 1px solid #ddd;
}

.voicemailbox-container {
	
	width: 100%;
	display: inline-block;
}

.voicemailbox-wrapper {
	padding: 24px 0;
	margin: 0 auto;
	width: 100%;
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
	border-radius: 3px;
}

.voicemailbox-wrapper:hover {
	box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.2);
}

.voicemail-list {
	display: flex;
	margin-top: 20px;
}

.voicemailbox {
	width: 100%;
	text-align: center;
}

.voicemailbox .voicemail-mailbox>div {
	width: 50%;
}

.voicemailbox .voicemail-mailbox {
	display: flex;
	margin: 0 auto;
	width: 45%;
	color: #808080ed;
}

.newcount {
	color: #33db24 !important;
}

.totalcount {
	color: #151517;
}


.light .num-title {
	font-size: 14px;
	font-weight: 500;
}

.dark .num-title {
	font-size: 14px;
	font-weight: 500;
	color: #ffffff;
}

.dark .voicemails-top {
	background-color: #6e707a;
}

.light .voicemails-top {
	background-color: #FFFFFF;
}

.dark-voicemailbox-wrapper {
	background-color: #6e707a;
}

.light-voicemailbox-wrapper {
	background-color: #FFFFFF;
}

.voicemails-top-1 h1,
.voicemails-top-2 h1 {
	margin-bottom: 0;
	font-size: 32px;
	/* color: #151517; */
}

.voicemails-top-1 h2,
.voicemails-top-2 h2 {
	margin-bottom: 0;
	font-size: 16px;
	/* color: #151517; */
}

.voicemails-top-2 {
	/* border-top: 4px solid #ffffff; */
	border-top: 4px solid #2DCB1A;
}

.voicemails-top-1 {
	border-top: 4px solid #33db24;
	margin-right: 2px;
}

.back-box {
	cursor: pointer;
	font-size: 15px;
	color: gray;
	margin-top: 18px;
	margin-bottom: 17px;
}

.light .vmbox-title {
	font-size: 23px;
	color: #151517;
	margin-bottom: 24px;
}

.dark .vmbox-title {
	font-size: 23px;
	color: #ffffff;
	margin-bottom: 24px;
}

.voicemail-top-wrap {
	display: block;
	color: #808080ed;
	margin-bottom: 24px;
}

.voicemails-top {
	width: 11rem;
	text-align: center;
	line-height: 2rem;
	border-radius: 0.2rem;
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
	padding: 15px 20px 15px 20px;
	margin-right: 1px;
	float: left;
	background-color: white !important;
}

.checkbox-wrap {
	padding: 5px 15px;
	border: solid 1px #BCBCC2;
	border-radius: 2px;
	margin-bottom: 1rem;
	font-weight: 300;
	background: #F9F9F9;
	margin-right: 0;
	float: left;
	display: block;
}

.checkbox-wrap>input[type="checkbox"] {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}

.voicemail-search {
	display: block;
	float: right;
}

.voicemail-search input[type="text"] {
	width: 15rem;
}

.bottom-nav {
	float: right;
	margin-bottom: 1rem;
	margin-top: 1rem;
	font-size: 14px;
}

.bottom-nav button {
	font-size: 0.8rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 0.1rem;
	color: grey;
	background: #F7F7F7;
	border: 1px solid #CDCDD0;
	margin-right: 0;
	margin: 0;
}

.bottom-nav button:first-of-type,
.bottom-nav button:nth-of-type(2) {
	color: lightgrey;
}

.bottom-nav button:nth-of-type(3) {
	background: #D3D3D6;
	color: black;
}

.bottom-nav select {
	height: 2rem;
	border: 1px solid grey;
	padding: 1rem 3rem 1rem 0;
	margin: 0 10px;
	color: black;
	font-size: 0.7rem;
	font-weight: 400;
}

.page-num {
	font-size: 0.7rem;
	margin-right: 1rem;
	margin-left: 1rem;
}

.button-disable {
	color: grey !important;
}

.button-enable {
	color: #000 !important;
}

.voicemailtable {
	border: 0px;
	margin: 0 15px 0 15px !important;
	width: 100%;
}

.row1 {
	color: grey;
	display: flex;
	flex-wrap: wrap;
	font-size: 12px;
	margin-left: 16px;
	width: 100%;
}

.voicemail-row {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px 0px;
	margin-top: -1px;
	float: left;
	width: 100%;
	border-top: solid 1px #BCBCC2;
	border-bottom: solid 1px #BCBCC2;
}

.disabledbutton {
	pointer-events: none;
	opacity: 0.5;
}

.checkbox {
	width: 15px !important;
	height: 15px !important;
	margin-left: -10px;
}

.status {
	margin-left: -15px;

}

.menu-postion {
	position: absolute;
	top: 10px !important;
	left: -57px !important;
}

.newstatus {
	background-color: #33db24 !important;
	border-color: #33db24 !important;
	color: #071F05;
	border-radius: 15px;
	padding: 4px 12px 4px 12px;
	font-size: 12px;
}

.listenedstatus {
	background-color: #d0d0d9 !important;
	border-color: #d0d0d9 !important;
	color: #071F05;
	border-radius: 15px;
	padding: 4px 12px 4px 12px;
	font-size: 12px;
}

.deletedstatus {
	background-color: #ff3d24 !important;
	border-color: #ff3d24 !important;
	color: #ffffff;
	border-radius: 15px;
	padding: 4px 12px 4px 12px;
	font-size: 12px;
}

.mailchange {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.audioplay {
	cursor: pointer;
}

.delete {
	cursor: pointer;
}

.audiodown {
	cursor: pointer;
}

.name {
	margin-bottom: 0;
	font-size: 16px;
}

.number {
	font-size: 14px;
	color: gray;
}

.audio-close {
	background-color: #f6f6f9;
	overflow: hidden;
	border: solid 1px #e1e1e7;
	margin-left: -31px;
	padding: 8px;
	font-size: 15px;
	padding-right: 10px !important;
	padding-left: 10px !important;
}

.gray-icon {
	width: 18px;
	height: 18px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
	color: gray;
}
/* customize  */

.voicemails {
	background: #F2F2F3;
  display: inline-block;
  width: 100%;
	overflow-y: scroll;
	position: relative;
}
.voicemails td {
	border-bottom: 1px solid #ddd;
}
.voicemail-top-wrap {
	display: block;
	color: #808080ed;
	margin-bottom: 24px;
}
.back-box{
	cursor: pointer;
	font-size: 12px;
	color: gray;
	margin-top: 32px;
	margin-bottom: 17px;
}
.vmbox-title{
	font-size: 23px;
	color: #151517;
	margin-bottom: 24px;
}
.voicemails-top {
	background: #FFFFFF;
	width: 11rem;
	text-align: center;
	line-height: 2rem;
	border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
  padding: 15px 20px 15px 20px;
  margin-right: 1px;
  float: left;
}
.voicemails-top-1 h1, .voicemails-top-2 h1{
	margin-bottom: 0;
	font-size: 32px;
	color: #151517;
}
.voicemails-top-1 h2, .voicemails-top-2 h2{
	margin-bottom: 0;
	font-size: 16px;
}
/* .voicemails-top-2 {
	border-top: 4px solid #ffffff;
} */
.voicemails-top-1 {
	border-top: 4px solid #33db24;
	margin-right: 2px;
}
.checkbox-wrap {
	padding: 7px 15px;
	border: solid 1px #BCBCC2;
	border-radius: 2px;
  margin-bottom: 1rem;
  font-weight: 300;
  background: #F9F9F9;
  margin-right: 0;
  float: left;
	display: block;
}
.checkbox-wrap > input[type="checkbox"] {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}
.direction-down{
	font-size: 0.6rem;
	margin-left: 40px !important;
	padding-top: 6px;
	margin: auto 0;
	cursor: pointer;
	color: grey;
}
.direction-down .dropdown-menu {
	top: 12px !important;
	left: -56px !important;
}
#voicemail-search {
	display: block;
	float: right;
}

#voicemail-search input[type="text"] {
	width: 15rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAAAXNSR0IArs4c6QAAAiVJREFUOBGNkk9oE0EUxmdmN1LBBkRQ6U0ERcRaUG+REm/2Ir1ZipVUaGmwyUEvRRBEKQUvzW4JtqHF/lEkCPXmxYMHPXhRQfSgKKiHRS8V2kIg2R1/k80staTSgdm37/u+efPNvJFi2/C8+dNah+PAF4TQXcQN5nvmk+7uYw+z2WyD/3+GtFm1WnWC4M8U+Q2tdYJbPo7yo5Sp/mIx92UrrmwSBGtlFt80uZTygeM4mc5ON+268rhS8hpwgLOTQtRflsuP9tt1JrrmUypVLmkdjUgp6sy+QmHkhcFbY534mYWr9frmKhv1EstgA1bQcqJvtYC72wpYncjnB9dwMoTPdQpd9v3ZU5ZU09OVQ4DnABrp9J77lmgXi8XRH1LqFcOFoei1GgV41CTcw6dcLlezxH/i21gvzlqN4gJ1nOzUESuNI5s1O6e14HjxoEjqKzgnEido815L7BSjSJwxHGt+Wo0aGxv6TfKG6dLmCUu0i75fOcJ+g3CNVMp9ZjXN7kDcMwBuJmh3nyW3xpmZxQNhqJfR7MPFUj4//M3yycv0vDk/ivR1QyBaoORKR4f4UKs5h4WIMpz3DtTBmBePeQpX0EXN3HzMQCR9f26cM5un3/ZuuNJXcD2xG7FIoWFTKHn2JLpQGPV45j1KiUn8vAb7xcJ3xKeOo7K8k/MUuQi2QaGruF9gc5UcB3LXo1SazSB+3nJ0O3Gy6woIcWSOhSP5HcebfwEA/8LKeVzc1AAAAABJRU5ErkJggg==) no-repeat scroll;
	background-position: 95% 50%;
	background-color: white;
	font-size: 14px;
}

.voicemail tbody,
.voicemail thead {
	background: #F2F2F3;
}

.voicemail tbody {
	line-height: 1rem;
	font-size: 0.82rem;
}

.voicemail tbody > tr {
	border-bottom: 1px solid #CFCFD6;
	border-left: 1px solid #CFCFD6;
	border-right: 1px solid #CFCFD6;
}

.voicemail tbody > tr > td:last-of-type {
	text-align: right;
}

.voicemail tbody > tr > td:last-of-type img {
	margin-right: 0.5rem;
}

.voicemail tbody > tr > td:first-of-type {
	text-align: center;
}

.voicemail tbody > tr > td:last-of-type img {
	float: right;
}

.voicemailbox-container {
	width:100%;
	display: inline-block;
}

.voicemailbox-wrapper {
	padding: 24px 0;
	margin:0 auto;
	width: 100%;
	box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
	background-color: #ffffff;
	border-radius: 3px;
}
.voicemailbox-wrapper:hover {
	box-shadow: 0 0 30px 2px rgba(0,0,0,0.2);
}
.voicemailbox .voicemail-mailbox > div {
	width: 50%;
}

.voicemailbox .voicemail-mailbox {
	display: flex;
	margin: 0 auto;
	width: 45%;
	color: #808080ed;
}

.voicemailbox-title {
	text-align: left;
	margin-top: 32px;
	margin-bottom: 24px;
	font-size: 14px;
}

.voicemailbox {
	width: 100%;
	text-align: center;
}

.newcount {
	color: #33db24 !important;
}
.totalcount{
	color: #151517;
}

.bottom-nav {
	float: right;
	margin-bottom: 1rem;
	margin-top: 1rem;
	font-size: 0;
}

#page-num {
	font-size: 0.7rem;
	margin-right: 1rem;
	margin-left: 1rem;
}

.bottom-nav button {
	font-size: 0.8rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 0.1rem;
	color: grey;
	background: #F7F7F7;
	border: 1px solid #CDCDD0;
	margin-right: 0;
	margin: 0;
}
.button-disable{
	color: grey !important;
}
.button-enable{
	color: #000 !important;
}
.bottom-nav button:first-of-type,
.bottom-nav button:nth-of-type(2) {
	color: lightgrey;
}

.bottom-nav button:nth-of-type(3) {
	background: #D3D3D6;
	color: black;
}
.voicemail-content{
	background-color: #2DCB1A;
	padding: 4px 11px;
	border-radius: 50%;
	color: #222224;
	font-weight: bold;
	text-align: center;
}
.voicemail-icon{
	width: 18px;
	height: 18px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
	color: #ffffff;
}
audio {
  width: 100%;
}

.container-audio {
  width: 100%;
  height: auto;
  border-radius: 1px;
  background-color: #f6f6f9;
  overflow: hidden;
  border: solid 1px #e1e1e7;
  height: 40px;
  margin-right: 15px;

}

audio::-webkit-media-controls-panel {
  background-color: transparent;
  margin-bottom: -20px;
  margin-top: -30px;
  padding: 0px;
  height: 100px;
}

audio::-webkit-media-controls-fullscreen-volume-slider {
  display: block;
}

audio::-webkit-media-controls-closed-captions-container {
  display: block;
}

audio::-webkit-media-controls-closed-captions-track-list {
  display: block;
}

audio::-webkit-media-controls-time-remaining-display {
  display: none;
}

audio::-webkit-media-controls-download-button {
  display: none;
}

.audio-close {
  background-color: #f6f6f9;
  overflow: hidden;
  border: solid 1px #e1e1e7;
  margin-left: -31px;
  padding: 8px;
  font-size: 15px;
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.num_device {
	background: #F2F2F3;
  display: inline-block;
	width: 100%;
	overflow-y: scroll;
	position: relative;
}
.devices-top-wrap-red {
	border: 1px solid #FC231C;
}
.content{
	margin-top:32px;
	margin-bottom: 24px;
	font-size: 16px;
}
.devices-top {
	padding-top: 1.7rem;
	background: #FFFFFF;
	text-align: center;
	line-height: 1.7rem;
	border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
  padding-bottom: 0.5rem;
  float: left;
	position: relative;
	height: 100%;
	width:100%;
}
.devices-top h2 {
	font-size: 2.7rem;
}
.devices-top p {
	margin: 0;
	margin-bottom: 0.1rem !important;
}
.donut {
	text-align: center;
	display: inline-block;
	font-size: 70px !important;
}
.active_line {
	cursor: pointer;
	color: #2091FF;
	border-bottom: 2px solid #2091FF;
	font-weight: 500;
	padding-bottom: 0.5rem;
}
.numbers-wrap {
	display: inline-block;
	text-align: center;
}
.numbers-wrap h2{
	font-size: 32px;
}
.devices-name p {
	margin-bottom: 0;
	cursor: pointer;
}
.devices-top p:first-of-type {
	margin-top: 0.5rem;
}
.devices-top p {
	margin-bottom: 0.5rem;

}
hr {
	margin-top: 0px !important;
	margin-bottom: 1rem !important;
	border: 0 !important;
	border-top: 2px solid rgba(0, 0, 0, 0.1) !important;
}
.corner {
	position: absolute;
	top: 1rem;
	right: 1rem;
}
.device-box {
	padding-top: 1.7rem;
	background: #FFFFFF;
	text-align: center;
	line-height: 1.7rem;
	border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
  padding-bottom: 0.5rem;
	position: relative;
	width: 100%;
	height: 100%;
	display: inline-block;
	margin-bottom: 10px;
}
.numbers-posit {	
	text-align: right ;
}
.circle-posit {	
	text-align: left ;
}

@media only screen and (max-width: 600px) {
	.numbers-posit {	
		text-align: center !important;
	}
	.circle-posit {	
		text-align: center ;
	}
}  
/****************************************************************
 *
 * CSS Percentage Circle
 * Author: Andre Firchow
 *
*****************************************************************/
.rect-auto, .c100.p51 .slice, .c100.p52 .slice, .c100.p53 .slice, .c100.p54 .slice, .c100.p55 .slice, .c100.p56 .slice, .c100.p57 .slice, .c100.p58 .slice, .c100.p59 .slice, .c100.p60 .slice, .c100.p61 .slice, .c100.p62 .slice, .c100.p63 .slice, .c100.p64 .slice, .c100.p65 .slice, .c100.p66 .slice, .c100.p67 .slice, .c100.p68 .slice, .c100.p69 .slice, .c100.p70 .slice, .c100.p71 .slice, .c100.p72 .slice, .c100.p73 .slice, .c100.p74 .slice, .c100.p75 .slice, .c100.p76 .slice, .c100.p77 .slice, .c100.p78 .slice, .c100.p79 .slice, .c100.p80 .slice, .c100.p81 .slice, .c100.p82 .slice, .c100.p83 .slice, .c100.p84 .slice, .c100.p85 .slice, .c100.p86 .slice, .c100.p87 .slice, .c100.p88 .slice, .c100.p89 .slice, .c100.p90 .slice, .c100.p91 .slice, .c100.p92 .slice, .c100.p93 .slice, .c100.p94 .slice, .c100.p95 .slice, .c100.p96 .slice, .c100.p97 .slice, .c100.p98 .slice, .c100.p99 .slice, .c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.pie, .c100 .bar, .c100.p51 .fill, .c100.p52 .fill, .c100.p53 .fill, .c100.p54 .fill, .c100.p55 .fill, .c100.p56 .fill, .c100.p57 .fill, .c100.p58 .fill, .c100.p59 .fill, .c100.p60 .fill, .c100.p61 .fill, .c100.p62 .fill, .c100.p63 .fill, .c100.p64 .fill, .c100.p65 .fill, .c100.p66 .fill, .c100.p67 .fill, .c100.p68 .fill, .c100.p69 .fill, .c100.p70 .fill, .c100.p71 .fill, .c100.p72 .fill, .c100.p73 .fill, .c100.p74 .fill, .c100.p75 .fill, .c100.p76 .fill, .c100.p77 .fill, .c100.p78 .fill, .c100.p79 .fill, .c100.p80 .fill, .c100.p81 .fill, .c100.p82 .fill, .c100.p83 .fill, .c100.p84 .fill, .c100.p85 .fill, .c100.p86 .fill, .c100.p87 .fill, .c100.p88 .fill, .c100.p89 .fill, .c100.p90 .fill, .c100.p91 .fill, .c100.p92 .fill, .c100.p93 .fill, .c100.p94 .fill, .c100.p95 .fill, .c100.p96 .fill, .c100.p97 .fill, .c100.p98 .fill, .c100.p99 .fill, .c100.p100 .fill {
  position: absolute;
  border: 0.08em solid #307bbb;
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pie-fill, .c100.p51 .bar:after, .c100.p51 .fill, .c100.p52 .bar:after, .c100.p52 .fill, .c100.p53 .bar:after, .c100.p53 .fill, .c100.p54 .bar:after, .c100.p54 .fill, .c100.p55 .bar:after, .c100.p55 .fill, .c100.p56 .bar:after, .c100.p56 .fill, .c100.p57 .bar:after, .c100.p57 .fill, .c100.p58 .bar:after, .c100.p58 .fill, .c100.p59 .bar:after, .c100.p59 .fill, .c100.p60 .bar:after, .c100.p60 .fill, .c100.p61 .bar:after, .c100.p61 .fill, .c100.p62 .bar:after, .c100.p62 .fill, .c100.p63 .bar:after, .c100.p63 .fill, .c100.p64 .bar:after, .c100.p64 .fill, .c100.p65 .bar:after, .c100.p65 .fill, .c100.p66 .bar:after, .c100.p66 .fill, .c100.p67 .bar:after, .c100.p67 .fill, .c100.p68 .bar:after, .c100.p68 .fill, .c100.p69 .bar:after, .c100.p69 .fill, .c100.p70 .bar:after, .c100.p70 .fill, .c100.p71 .bar:after, .c100.p71 .fill, .c100.p72 .bar:after, .c100.p72 .fill, .c100.p73 .bar:after, .c100.p73 .fill, .c100.p74 .bar:after, .c100.p74 .fill, .c100.p75 .bar:after, .c100.p75 .fill, .c100.p76 .bar:after, .c100.p76 .fill, .c100.p77 .bar:after, .c100.p77 .fill, .c100.p78 .bar:after, .c100.p78 .fill, .c100.p79 .bar:after, .c100.p79 .fill, .c100.p80 .bar:after, .c100.p80 .fill, .c100.p81 .bar:after, .c100.p81 .fill, .c100.p82 .bar:after, .c100.p82 .fill, .c100.p83 .bar:after, .c100.p83 .fill, .c100.p84 .bar:after, .c100.p84 .fill, .c100.p85 .bar:after, .c100.p85 .fill, .c100.p86 .bar:after, .c100.p86 .fill, .c100.p87 .bar:after, .c100.p87 .fill, .c100.p88 .bar:after, .c100.p88 .fill, .c100.p89 .bar:after, .c100.p89 .fill, .c100.p90 .bar:after, .c100.p90 .fill, .c100.p91 .bar:after, .c100.p91 .fill, .c100.p92 .bar:after, .c100.p92 .fill, .c100.p93 .bar:after, .c100.p93 .fill, .c100.p94 .bar:after, .c100.p94 .fill, .c100.p95 .bar:after, .c100.p95 .fill, .c100.p96 .bar:after, .c100.p96 .fill, .c100.p97 .bar:after, .c100.p97 .fill, .c100.p98 .bar:after, .c100.p98 .fill, .c100.p99 .bar:after, .c100.p99 .fill, .c100.p100 .bar:after, .c100.p100 .fill {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100 {
  position: relative;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  background-color: #cccccc;
}
.c100 *, .c100 *:before, .c100 *:after {
  box-sizing: content-box;
}
.c100.center {
  float: none;
  margin: 0 auto;
}
.c100.big {
  font-size: 240px;
}
.c100.small {
  font-size: 80px;
}
.c100 > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: #cccccc;
  display: block;
  text-align: center;
  white-space: nowrap;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.c100:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: whitesmoke;
  width: 0.84em;
  height: 0.84em;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}
.c100.p1 .bar {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.c100.p2 .bar {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.c100.p3 .bar {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.c100.p4 .bar {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.c100.p5 .bar {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}
.c100.p6 .bar {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.c100.p7 .bar {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.c100.p8 .bar {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.c100.p9 .bar {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.c100.p10 .bar {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}
.c100.p11 .bar {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.c100.p12 .bar {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.c100.p13 .bar {
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.c100.p14 .bar {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.c100.p15 .bar {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}
.c100.p16 .bar {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.c100.p17 .bar {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.c100.p18 .bar {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.c100.p19 .bar {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.c100.p20 .bar {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}
.c100.p21 .bar {
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.c100.p22 .bar {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.c100.p23 .bar {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.c100.p24 .bar {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.c100.p25 .bar {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.c100.p26 .bar {
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.c100.p27 .bar {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.c100.p28 .bar {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.c100.p29 .bar {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.c100.p30 .bar {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}
.c100.p31 .bar {
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.c100.p32 .bar {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.c100.p33 .bar {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.c100.p34 .bar {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.c100.p35 .bar {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}
.c100.p36 .bar {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.c100.p37 .bar {
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.c100.p38 .bar {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.c100.p39 .bar {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.c100.p40 .bar {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}
.c100.p41 .bar {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.c100.p42 .bar {
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.c100.p43 .bar {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.c100.p44 .bar {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.c100.p45 .bar {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}
.c100.p46 .bar {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.c100.p47 .bar {
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.c100.p48 .bar {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.c100.p49 .bar {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.c100.p50 .bar {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c100.p51 .bar {
  -webkit-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.c100.p52 .bar {
  -webkit-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}
.c100.p53 .bar {
  -webkit-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.c100.p54 .bar {
  -webkit-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.c100.p55 .bar {
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
}
.c100.p56 .bar {
  -webkit-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.c100.p57 .bar {
  -webkit-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}
.c100.p58 .bar {
  -webkit-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.c100.p59 .bar {
  -webkit-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.c100.p60 .bar {
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
}
.c100.p61 .bar {
  -webkit-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.c100.p62 .bar {
  -webkit-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}
.c100.p63 .bar {
  -webkit-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.c100.p64 .bar {
  -webkit-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.c100.p65 .bar {
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
}
.c100.p66 .bar {
  -webkit-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.c100.p67 .bar {
  -webkit-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}
.c100.p68 .bar {
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.c100.p69 .bar {
  -webkit-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.c100.p70 .bar {
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
}
.c100.p71 .bar {
  -webkit-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.c100.p72 .bar {
  -webkit-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.c100.p73 .bar {
  -webkit-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.c100.p74 .bar {
  -webkit-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}
.c100.p75 .bar {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.c100.p76 .bar {
  -webkit-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.c100.p77 .bar {
  -webkit-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.c100.p78 .bar {
  -webkit-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.c100.p79 .bar {
  -webkit-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}
.c100.p80 .bar {
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
}
.c100.p81 .bar {
  -webkit-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.c100.p82 .bar {
  -webkit-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.c100.p83 .bar {
  -webkit-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.c100.p84 .bar {
  -webkit-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}
.c100.p85 .bar {
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
}
.c100.p86 .bar {
  -webkit-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.c100.p87 .bar {
  -webkit-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.c100.p88 .bar {
  -webkit-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.c100.p89 .bar {
  -webkit-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}
.c100.p90 .bar {
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
}
.c100.p91 .bar {
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.c100.p92 .bar {
  -webkit-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.c100.p93 .bar {
  -webkit-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.c100.p94 .bar {
  -webkit-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}
.c100.p95 .bar {
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
}
.c100.p96 .bar {
  -webkit-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.c100.p97 .bar {
  -webkit-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.c100.p98 .bar {
  -webkit-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.c100.p99 .bar {
  -webkit-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}
.c100.p100 .bar {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.c100:hover {
  cursor: default;
}
.c100:hover > span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  color: #307bbb;
}
.c100:hover:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}
.c100.dark {
  background-color: #777777;
}
.c100.dark .bar,
.c100.dark .fill {
  border-color: #c6ff00 !important;
}
.c100.dark > span {
  color: #777777;
}
.c100.dark:after {
  background-color: #666666;
}
.c100.dark:hover > span {
  color: #c6ff00;
}
.c100.green .bar, .c100.green .fill {
  border-color: #4db53c !important;
}
.c100.green:hover > span {
  color: #4db53c;
}
.c100.green.dark .bar, .c100.green.dark .fill {
  border-color: #5fd400 !important;
}
.c100.green.dark:hover > span {
  color: #5fd400;
}
.c100.orange .bar, .c100.orange .fill {
  border-color: #dd9d22 !important;
}
.c100.orange:hover > span {
  color: #dd9d22;
}
.c100.orange.dark .bar, .c100.orange.dark .fill {
  border-color: #e08833 !important;
}
.c100.orange.dark:hover > span {
  color: #e08833;
}

.faxes {
	background: #f4f4f5;
	display: inline-block;
	width: 100%;
	overflow: scroll;
	position: relative;
}

.fax-container {
	margin: 32px 24px 0px 24px;
	text-align: left;
}
#faxes table {
	padding-top: 0.8rem;
}

.fax-search {
	position: relative;
	display: flex;
	justify-content: flex-end;
}

.fax-search-button {
	margin-top: 10px;
	border-radius: 0.2rem;
	color: #64646c;
	background: #f7f7f7;
	border: 1px solid #cdcdd0;
	padding: 6px 13px;
	display: flex;
	align-items: center;
}
.fax-search-button:hover {
	color: #2297ff;
}

.calendar1 {
	width: 130px;
	/* background: url('../../assets/images/calendar.png') no-repeat scroll; */
	background-position: 95% 50%;
	background-color: white;
	position: relative;
	bottom: 0;
}
.fax-search label {
	display: block;
	text-align: left;
	margin-left: 0.15rem;
	font-size: 0.8rem;
	color: grey;
}
.fax-search-text {
	margin-top: 10px;
	width: 15rem !important;
	/* background: url('../../assets/images/magnifying-glass.png') no-repeat scroll; */
	background-position: 95% 50%;
	background-color: white;
}
.fax-checkbox-wrap {
	padding: 7px 15px;
	border: solid 1px #bcbcc2;
	border-radius: 2px;
	font-weight: 300;
	background: #f9f9f9;
	float: left;
	display: block;
	margin-top: 10px;
}
.fax-checkbox-trash {
	padding: 7px 10px;
	border: solid 1px #bcbcc2;
	border-radius: 2px;
	font-weight: 300;
	background: #f9f9f9;
	margin-left: 16px;
	float: left;
	display: block;
	margin-top: 10px;
	cursor: pointer;
}

.fax-checkbox-wrap > input[type="checkbox"] {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}

.completedstatus {
	background-color: #33db24 !important;
	border-color: #33db24 !important;
	color: #071f05;
	border-radius: 15px;
	padding: 4px 12px 4px 12px;
	font-size: 12px;
}
.faxtable {
	border: 0px;
	margin-top: 24px !important;
	width: 100%;
}
.table-th {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: grey;
	font-size: 12px;
	margin-bottom: 10px;
	padding: 0 15px;
}
.fax-row {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px;
	margin-top: -1px;
	float: left;
	width: 100%;
	border-top: solid 1px #bcbcc2;
	border-bottom: solid 1px #bcbcc2;
}
.fax-row:hover,
.fax-row-active {
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	border-radius: 3px;
}
.table-td {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.button-disable {
	color: grey !important;
}
.button-enable {
	color: #000 !important;
}
.calltable-thead th {
	padding: 0px !important;
	color: grey;
	font-weight: bold;
}

.faxes-view-all {
	color: #1e81ff;
	font-size: 1rem;
	cursor: pointer;
	margin-left: 20px;
}
.rencent-faxes {
	height: 270px;
	overflow: auto;
}

.total-fax-content {
	background-color: #f4f4f5;
	padding: 4px 11px;
	border-radius: 50%;
	color: #222224;
	font-weight: bold;
	text-align: center;
}

.apply-icon {
	width: 18px;
	height: 18px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
	margin-right: 8px;
}

.fax-icon {
	width: 18px;
	height: 18px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
	color: gray;
}

.fax-title {
	padding: 10px 10px 15px 5px;
	font-size: 16px;
	font-weight: 400;
}

.faxes-box {
	background: #ffffff;
	width: 100%;
	text-align: center;
	line-height: 2rem;
	border-radius: 0.2rem;
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
	padding: 10px 20px 20px 20px;
	float: left;
	height: 350px;
}

.send-fax {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.send-fax-button {
	border-radius: 0.2rem;
	color: #151517;
	background: #2297ff;
	border: 1px solid #006bca;
	padding: 6px 13px;
	display: flex;
	align-items: center;
}
.process-state {
	color: #64646c;
	height: 10px;
	width: 10px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
	margin-right: 5px;
}
.send-state {
	color: #dbdbde;
	height: 10px;
	width: 10px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
	margin-right: 5px;
}
.failed-state {
	color: #ff3d24;
	height: 10px;
	width: 10px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
	margin-right: 5px;
}
.received-state {
	color: #dbdbde;
	height: 10px;
	width: 10px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
	margin-right: 5px;
}
#sendfax-modal1 .modal-content {
	width: 684px;
}
#sendfax-modal2 .modal-header {
	background-color: #151517;
	height: 55px;
	color: #ffffff;
}
#sendfax-modal2 .modal-header .button .span {
	color: #ffffff;
}
.sendfax-main {
	margin: 0 auto;
}
.upload-label {
	font-size: 12px;
	color: #64646c;
}
.ql-snow .ql-picker {
	height: 28px !important;
}
.ql-editor {
	height: 240px !important;
}
.cancel-btn {
	cursor: pointer;
	border-radius: 0.2rem;
	color: #64646c;
	border: 1px solid #ffffff;
	padding: 6px 13px;
}
.choose-btn {
	border-radius: 0.1rem;
	color: #64646c;
	background: #f7f7f7;
	border: 1px solid #cdcdd0;
	padding: 6px 13px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.choose-btn:hover {
	color: #151517;
}
#filter-dropdown .dropdown-menu {
	box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2) !important;
	border: none;
	top: 10px !important;
}
.filter-fax {
	width: 458px;
	padding: 5px 16px 16px 16px;
}
.button-group{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.fax-btn-all{
	margin-right: -2px
}
.fax-type-btn-active{
	background: #dbdbde !important;
	border: 1px solid #bcbcc2 !important;
	color: #151517;
}
.fax-icon-filter{
	height: 14px;
	width: 14px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle;
}
.line-div{
	margin: 10px 0px;
	border: 1px solid #dbdbde;
}
.round-pending-state{
	background: #64646c;
	color: #ffffff;
	border-radius: 10px;
	font-size: 12px;
	font-weight: 600;
	padding: 3px 8px;
}
.round-faild-state{
	background: #ff3d24;
	color: #151517;
	border-radius: 10px;
	font-size: 12px;
	font-weight: 600;
	padding: 3px 8px;
}
.round-send-state{
	background: #dbdbde;
	color: #151517;
	border-radius: 10px;
	font-size: 12px;
	font-weight: 600;
	padding: 3px 8px;
}
.filter-calendar{
	width: 140px !important;
	/* background: url('../../assets/images/calendar.png') no-repeat scroll; */
	background-position: 95% 50%;
	background-color: white;
	position: relative;
	bottom: 0;
}
.button-group1{
	display: flex;
	justify-content:space-between;
	align-items: center;
}
input[type="file"] {
  display: none;
}
.border-red{
	border: 1px solid #ff3d24;
}
#devices td {
	width: 50%;
	text-align: left;
	padding: 0.4rem 0 0.4rem 0 !important;
}
#devices div {
	vertical-align: middle;
}
.divice-title{
	padding:10px 10px 10px 0px;
	font-size: 16px;
	font-weight: 400;
}
#devices thead > tr > th:first-of-type {
	font-weight: 400;
	font-size:1.2rem;
  color: grey;
}
#devices thead > tr > th:nth-of-type(2) {
	padding-top: 1.3rem;
	font-size: 1.1rem
}
#devices td img {
  margin-right: 1rem;
  vertical-align: middle;
  display: inline-block;
}
#devices tbody > tr > td:first-of-type {
  font-size: 1.1rem;
}
#device-id {
  font-size: 0.7rem;
  color: grey;
}
#devices tbody > tr > td:last-of-type div:nth-of-type(1) {
	font-size: 1rem;
}
.color-red{
	color: #FF3D24 !important;
}

.num-title{
  font-size: 14px;
	font-weight: 500;
}
.device-scroll{
	margin-top: 15px;
	text-align: center;
	display: flex;
	align-items: center;
}
.devices {
	margin: 0 10px;
	padding-top: 1.7rem;
	background: #FFFFFF;
	text-align: center;
	line-height: 1.7rem;
	border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
  padding-bottom: 0.5rem;
	position: relative;
	width: 260px;
	display: inline-block;
	height: 95%;
}

.device-container{
	padding: 15px 0px;
  overflow-x: hidden;
	overflow-y: hidden;
	display: inline-block;
	white-space: nowrap;
	height: 220px;
	width: 100%;
}
.prev, .next {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
  margin: 0 auto;
  cursor: pointer;
	color: #64646c !important;
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
}
.next {
  margin-left: 5px;
}
.prev {
  margin-right: 5px;
}
.prev:hover, .next:hover {
  color: #151517 !important;
	height: 100%;
}
.corner-icon{
	width: 17px;
	height: 17px;
	fill: currentColor;
	line-height: inherit;
	vertical-align: middle
}
.color-green{
	color: #2DCB1A !important;
}
#numbers thead > tr > th:first-of-type {
  font-weight: 400;
  color: grey;
  font-size: 1.2rem;
}

.font-grey {
  font-weight: 400;
  font-size: 1.2rem;
  color: grey;
}
#numbers h1 {
	font-size: 32px;
	margin-bottom: 2.2rem;
	font-weight: 300;
	font-weight: 420;
  color: black;
}
.number-title{
  padding: 10px 10px 10px 8px;
}
.float-right {
  text-align: center;
  float: right;
  display: inline-block;
  font-size: 120px;
  margin-right: 0rem;
  font-weight: 400;
  color: grey;
}
.number-box{
  display: flex;
	justify-content: center;
	align-items: center;
}
#devices thead > tr > th:first-of-type {
    font-weight: 400;
    font-size: 1.2rem;
    color: grey;
}
.number-graph{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.number-title-box{
  padding: 15px 0px 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.usage-today{
  font-size: 14px;
  font-weight: normal;
}
.border-div{
  border: 0.6px solid;
  color: #dddddd;
}
.selected-num{
  background: #FFFFFF;
  width: 100%;
  text-align: left;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
  padding: 16px;
  border-right: 2px solid #2297FF;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.unselected-num{
  background: #FFFFFF;
  width: 100%;
  text-align: left;
  border-radius: 0.2rem;
  padding: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.selected-num img{
  width: 20px;
}
.number-title{
  font-size: 16px;
  font-weight: 400;
}
.donut1 {
	text-align: center;
	display: inline-block;
	font-size: 90px !important;
}
.home-grid {
  margin: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 6rem 1fr 1fr 1fr;
  grid-row-gap: 1.75rem;
  grid-column-gap: 1rem;
  min-width: 52rem;
  margin-bottom: 2rem;
}
.home {
  background: #F2F2F3;
  display: inline-block;
  width: 100%;
	/* overflow-y: scroll; */
	position: relative;
}
/* .main > div:nth-of-type(2) {
  margin: 1rem;
} */
#missed-calls,
#new-voicemails,
#new-fax,
#new-messages {
  min-width: 12rem;
  vertical-align: middle;
}

#missed-calls >*,
#new-voicemails >*,
#new-fax >*,
#new-messages >* {
  margin-top: 0.5rem;
  margin-right: 0.4rem;
}

.home-grid > div {
  background: white;
  border-radius: 0.2rem;
  box-shadow: 0px 3px 10px 1px #ddd;
  height: 100%;
  position: relative;
  padding-bottom: 1rem;
}

.home-grid div h1 {
  float: right;
  margin: 0;
  padding: 0;
}

.home-grid div h4 {
  font-size: 1rem;
  font-weight: 300;
  padding: 0.75rem;
  margin: 0;
  float: left;
}

.view-all {
  color: #1E81FF;
  float: right;
  padding-right: 15px;
  padding-top: 10px;
  cursor: pointer;
}

.home-grid div > p {
  float: right;
}
.common-box{
  background: #FFFFFF;
  width: 100%;
  text-align: center;
  line-height: 2rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
  padding: 15px 20px 20px 20px;
  margin-right: 1px;
  float: left;
}
#missed-calls{
  border-top: 2px solid #FC231C;
}

#new-voicemails {
  border-top: 2px solid #2DCB1A;
}

#new-fax {
  border-top: 2px solid #F4F4F5;
}

#new-messages {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
}

#missed-calls .missed-content,
#new-voicemails .voicemail-content,
#new-fax .total-fax-content,
#new-messages img {
  float: left;
  margin-left: 1rem;
}

.text {
  float: right;
  font-weight: 300;
}
.text .text-count {
  display: block;
  padding-bottom: 0;
  font-size: 32px;
  font-weight: normal;
}
.text .text-value {
  font-size: 14px;
  font-weight: normal;
}
#devices thead,
#numbers thead,
#conferences thead {
  background: none;
}

.blue {
  color: #1E81FF;
}

#numbers select {
  width: 100%;
  font-size: 1.1rem;
}

#num {
  font-size: 32px;
  font-weight: 400;
  color: black;
  margin-right: 0.3rem;
}
#num .num-title{
  font-size: 14px;
  color: gray;
}

#num:nth-of-type(2) {
  margin-left: 1.5rem;
}

#numbers {
  height: 100%;
}

#numbers td {
  padding-top: 1rem;
}

#faxes {
  grid-row: 4 / 5;
  grid-column: 1 / 3;
}

#conferences {
  grid-row: 4 / 5;
  grid-column: 3 / 5;
}

#devices table > tbody > tr > td,
#faxes table > tbody > tr > td
 {
  border-bottom: 1px solid #ddd;
}
.call-title{
  width: 100%;
  padding: 10px 10px 15px 5px;
  display: flex;
}
.missed-call-box{
  background: #FFFFFF;
  width: 100%;
  text-align: center;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
  padding: 10px 15px 12px 15px;
  float: left;
  height: 370px;
}
.calltable-thead th{
  padding-bottom: 10px !important;
}

.devices-box{
  background: #FFFFFF;
  width: 100%;
  text-align: center;
  line-height: 2rem;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
  padding: 10px 20px 20px 20px;
  float: left;
  height: 350px;
}

.loader_container{
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  z-index: 1048;
  background-color: black;
  opacity: 0.8;
  touch-action: none;
}
.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #ff2300;
  width: 120px;
  height: 120px;
  opacity: 1;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.bg-primary {
  background: #3db16b !important;
  color: white !important;
}

.bg-primary-bright {
  background: #c0c5e4 !important;
}

.bg-primary-gradient {
  background: -moz- oldlinear-gradient(220deg, #3db16b, #65d993) !important;
  background: linear-gradient(230deg, #3db16b, #65d993) !important;
  color: white !important;
}

.bg-info {
  background: #00bcd4 !important;
  color: white !important;
}

.bg-info-bright {
  background: #e1efff !important;
}

.bg-info-gradient {
  background: -moz- oldlinear-gradient(220deg, #00bcd4, #28e4fc) !important;
  background: linear-gradient(230deg, #00bcd4, #28e4fc) !important;
  color: white !important;
}

.bg-secondary {
  background: dimgray !important;
  color: white !important;
}

.bg-secondary-bright {
  background: #ededed !important;
}

.bg-secondary-gradient {
  background: -moz- oldlinear-gradient(220deg, dimgray, #919191) !important;
  background: linear-gradient(230deg, dimgray, #919191) !important;
  color: white !important;
}

.bg-success {
  background: #0abb87 !important;
  color: white !important;
}

.bg-success-bright {
  background: #cef5ea !important;
}

.bg-success-gradient {
  background: -moz- oldlinear-gradient(220deg, #0abb87, #32e3af) !important;
  background: linear-gradient(230deg, #0abb87, #32e3af) !important;
  color: white !important;
}

.bg-danger {
  background: #ea4141 !important;
  color: white !important;
}

.bg-danger-bright {
  background: #fcd0df !important;
}

.bg-danger-gradient {
  background: -moz- oldlinear-gradient(220deg, #ea4141, #ff6969) !important;
  background: linear-gradient(230deg, #ea4141, #ff6969) !important;
  color: white !important;
}

.bg-danger-light {
  background: #ffd7d7 !important;
}

.bg-warning {
  background: #ff9d36 !important;
  color: white !important;
}

.bg-warning-bright {
  background: #ffe6cc !important;
}

.bg-warning-gradient {
  background: -moz- oldlinear-gradient(220deg, #ff9d36, #ffc55e) !important;
  background: linear-gradient(230deg, #ff9d36, #ffc55e) !important;
  color: white !important;
}

.bg-light {
  background: #e6e6e6 !important;
}

.bg-dark {
  background: #3a3f51 !important;
  color: white !important;
}

.bg-dark-bright {
  background: #d4d5d8 !important;
}

.bg-dark-gradient {
  background: -moz- oldlinear-gradient(220deg, #3a3f51, #585d6f) !important;
  background: linear-gradient(230deg, #3a3f51, #585d6f) !important;
  color: white !important;
}

.bg-facebook {
  background: #3b5998 !important;
  color: white !important;
}

.bg-twitter {
  background: #55acee !important;
  color: white !important;
}

.bg-linkedin {
  background: #0077b5 !important;
  color: white !important;
}

.bg-whatsapp {
  background: #43d854 !important;
  color: white !important;
}

.bg-instagram {
  background: #3f729b !important;
  color: white !important;
}

.bg-dribbble {
  background: #ea4c89 !important;
  color: white !important;
}

.bg-google {
  background: #db4437 !important;
  color: white !important;
}

.bg-youtube {
  background: #cd201f !important;
  color: white !important;
}

.text-primary {
  color: #3db16b !important;
}

.text-info {
  color: #00bcd4 !important;
}

.text-success {
  color: #0abb87 !important;
}

.text-danger {
  color: #ea4141 !important;
}

.text-warning {
  color: #ff9d36 !important;
}

.text-light {
  color: #e6e6e6 !important;
}

.text-facebook {
  color: #3b5998 !important;
}

.text-twitter {
  color: #55acee !important;
}

.text-google {
  color: #db4437 !important;
}

.text-linkedin {
  color: #0077b5 !important;
}

.text-instagram {
  color: #3f729b !important;
}

.text-whatsapp {
  color: #43d854 !important;
}

.text-dribbble {
  color: #ea4c89 !important;
}

a {
  color: #3db16b;  
  text-decoration: none;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

a:not([href]):not([tabindex]) {
  /* color: #3db16b; */
  /* color:#1E81FF !important; */
  text-decoration: none;
}

a.link-1 {
  color: black;
}

a.link-1:hover,
a.link-1:focus {
  color: #828282;
}

a.link-2 {
  color: black;
}

a.link-2:hover,
a.link-2:focus {
  color: #3db16b;
}

a:hover {
  text-decoration: none;
}

a:not(.active.list-group-item),
a:not(.btn):hover,
a:not(.btn):active,
a:not(.btn):focus {
  text-decoration: none !important;
  color: black;
  outline: none;
}

a.btn:hover,
a.btn:active,
a.btn:focus {
  text-decoration: none !important;
}

.btn {
  font-size: 14px;
  width: auto;
  display: inline-flex;
  padding: 10px 15px;
  line-height: 14px;
}

.btn[data-toggle='dropdown'] {
  align-items: center;
}

.btn.btn-sm {
  padding: 5px 10px;
  font-size: 13px;
}

.btn.btn-lg {
  padding: 15px 20px;
  font-size: 17px;
}

.btn.btn-block {
  width: 100%;
  justify-content: center;
}

.btn.btn-square {
  border-radius: 0;
}

.btn.btn-rounded {
  border-radius: 50px;
  padding: 10px 20px;
}

.btn.btn-rounded.btn-sm {
  padding: 5px 15px;
  font-size: 13px;
}

.btn.btn-rounded.btn-lg {
  padding: 20px 30px;
  font-size: 17px;
}

.btn.btn-floating {
  height: 35px;
  width: 35px;
  align-items: center;
  padding: 0;
  justify-content: center;
  border-radius: 50%;
}

.btn.btn-floating.btn-sm {
  height: 30px;
  width: 30px;
}

.btn.btn-floating.btn-lg {
  height: 50px;
  width: 50px;
}

.btn.btn-uppercase {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  align-items: center;
  font-weight: 600;
}

.btn.btn-uppercase.btn-sm {
  font-size: 11px;
}

.btn.btn-uppercase.btn-lg {
  font-size: 14px;
}

.btn.btn-shadow {
  box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.3);
}

.btn.btn-shadow:focus,
.btn.btn-shadow:active {
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.3) !important;
}

.btn.btn-primary,
a.btn[href='#next'],
a.btn[href='#previous'] {
  background: #3db16b;
  border-color: #3db16b;
}

.btn.btn-primary:not(:disabled):not(.disabled):hover,
a.btn:not(:disabled):not(.disabled):hover[href='#next'],
a.btn:not(:disabled):not(.disabled):hover[href='#previous'],
.btn.btn-primary:not(:disabled):not(.disabled):focus,
a.btn:not(:disabled):not(.disabled):focus[href='#next'],
a.btn:not(:disabled):not(.disabled):focus[href='#previous'],
.btn.btn-primary:not(:disabled):not(.disabled):active,
a.btn:not(:disabled):not(.disabled):active[href='#next'],
a.btn:not(:disabled):not(.disabled):active[href='#previous'] {
  background: #1f934d;
  border-color: #1f934d;
}

.btn.btn-primary:not(:disabled):not(.disabled):focus,
a.btn:not(:disabled):not(.disabled):focus[href='#next'],
a.btn:not(:disabled):not(.disabled):focus[href='#previous'] {
  box-shadow: 0 0 0 0.2rem rgba(61, 177, 107, 0.4) !important;
}

.btn.btn-primary.btn-pulse:not(:disabled):not(.disabled),
a.btn.btn-pulse:not(:disabled):not(.disabled)[href='#next'],
a.btn.btn-pulse:not(:disabled):not(.disabled)[href='#previous'] {
  box-shadow: 0 0 0 0 rgba(61, 177, 107, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-primary:hover,
a.btn:hover[href='#next'],
a.btn:hover[href='#previous'] {
  -webkit-animation: none;
}

.btn.btn-gradient-primary {
  background: -moz- oldlinear-gradient(70deg, #65d993, #2ea25c);
  background: linear-gradient(20deg, #65d993, #2ea25c);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-primary:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-primary:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-primary:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #3db16b, #2ea25c);
  background: linear-gradient(20deg, #3db16b, #2ea25c);
  border-color: transparent;
}

.btn.btn-gradient-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 177, 107, 0.5);
}

.btn.btn-gradient-primary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(61, 177, 107, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-primary:hover {
  -webkit-animation: none;
}

.btn.btn-light-primary {
  background: rgba(61, 177, 107, 0.3);
  border-color: transparent;
  color: #0b7f39;
}

.btn.btn-light-primary:not(:disabled):not(.disabled):hover,
.btn.btn-light-primary:not(:disabled):not(.disabled):focus,
.btn.btn-light-primary:not(:disabled):not(.disabled):active {
  background: rgba(61, 177, 107, 0.5);
  border-color: transparent;
}

.btn.btn-light-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 177, 107, 0.2);
}

.btn.btn-light-primary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(61, 177, 107, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-primary:hover {
  -webkit-animation: none;
}

.btn.btn-outline-primary {
  background: none;
  border-color: #3db16b;
  color: #33a761;
  border-width: 1px;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):hover {
  background: #3db16b;
  border-color: #3db16b;
  color: white;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):focus,
.btn.btn-outline-primary:not(:disabled):not(.disabled):active {
  background: #1f934d;
  border-color: #1f934d;
  color: white;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(61, 177, 107, 0.4);
}

.btn.btn-outline-primary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(61, 177, 107, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-primary:hover {
  -webkit-animation: none;
}

.btn.btn-secondary {
  background: dimgray;
  border-color: dimgray;
}

.btn.btn-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-secondary:not(:disabled):not(.disabled):active {
  background: #4b4b4b;
  border-color: #4b4b4b;
}

.btn.btn-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4) !important;
}

.btn.btn-secondary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-secondary {
  background: -moz- oldlinear-gradient(70deg, #919191, #5a5a5a);
  background: linear-gradient(20deg, #919191, #5a5a5a);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-secondary:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, dimgray, #5a5a5a);
  background: linear-gradient(20deg, dimgray, #5a5a5a);
  border-color: transparent;
}

.btn.btn-gradient-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.5);
}

.btn.btn-gradient-secondary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-light-secondary {
  background: rgba(105, 105, 105, 0.3);
  border-color: transparent;
  color: #373737;
}

.btn.btn-light-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-light-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-light-secondary:not(:disabled):not(.disabled):active {
  background: rgba(105, 105, 105, 0.5);
  border-color: transparent;
}

.btn.btn-light-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.2);
}

.btn.btn-light-secondary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-outline-secondary {
  background: none;
  border-color: dimgray;
  color: #5f5f5f;
  border-width: 1px;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):hover {
  background: dimgray;
  border-color: dimgray;
  color: white;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background: #4b4b4b;
  border-color: #4b4b4b;
  color: white;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4);
}

.btn.btn-outline-secondary.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-success {
  background: #0abb87;
  border-color: #0abb87;
}

.btn.btn-success:not(:disabled):not(.disabled):hover,
.btn.btn-success:not(:disabled):not(.disabled):focus,
.btn.btn-success:not(:disabled):not(.disabled):active {
  background: #009d69;
  border-color: #009d69;
}

.btn.btn-success:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.4) !important;
}

.btn.btn-success.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-success:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-success {
  background: -moz- oldlinear-gradient(70deg, #32e3af, #00ac78);
  background: linear-gradient(20deg, #32e3af, #00ac78);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-success:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-success:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-success:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #0abb87, #00ac78);
  background: linear-gradient(20deg, #0abb87, #00ac78);
  border-color: transparent;
}

.btn.btn-gradient-success:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);
}

.btn.btn-gradient-success.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-success:hover {
  -webkit-animation: none;
}

.btn.btn-light-success {
  background: rgba(10, 187, 135, 0.3);
  border-color: transparent;
  color: #008955;
}

.btn.btn-light-success:not(:disabled):not(.disabled):hover,
.btn.btn-light-success:not(:disabled):not(.disabled):focus,
.btn.btn-light-success:not(:disabled):not(.disabled):active {
  background: rgba(10, 187, 135, 0.5);
  border-color: transparent;
}

.btn.btn-light-success:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.2);
}

.btn.btn-light-success.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-success:hover {
  -webkit-animation: none;
}

.btn.btn-outline-success {
  background: none;
  border-color: #0abb87;
  color: #00b17d;
  border-width: 1px;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):hover {
  background: #0abb87;
  border-color: #0abb87;
  color: white;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):focus,
.btn.btn-outline-success:not(:disabled):not(.disabled):active {
  background: #009d69;
  border-color: #009d69;
  color: white;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.4);
}

.btn.btn-outline-success.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-success:hover {
  -webkit-animation: none;
}

.btn.btn-danger {
  background: #ea4141;
  border-color: #ea4141;
}

.btn.btn-danger:not(:disabled):not(.disabled):hover,
.btn.btn-danger:not(:disabled):not(.disabled):focus,
.btn.btn-danger:not(:disabled):not(.disabled):active {
  background: #cc2323;
  border-color: #cc2323;
}

.btn.btn-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 65, 65, 0.4) !important;
}

.btn.btn-danger.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 65, 65, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-danger:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-danger {
  background: -moz- oldlinear-gradient(70deg, #ff6969, #db3232);
  background: linear-gradient(20deg, #ff6969, #db3232);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-danger:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-danger:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-danger:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #ea4141, #db3232);
  background: linear-gradient(20deg, #ea4141, #db3232);
  border-color: transparent;
}

.btn.btn-gradient-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 65, 65, 0.5);
}

.btn.btn-gradient-danger.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 65, 65, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-danger:hover {
  -webkit-animation: none;
}

.btn.btn-light-danger {
  background: rgba(234, 65, 65, 0.3);
  border-color: transparent;
  color: #b80f0f;
}

.btn.btn-light-danger:not(:disabled):not(.disabled):hover,
.btn.btn-light-danger:not(:disabled):not(.disabled):focus,
.btn.btn-light-danger:not(:disabled):not(.disabled):active {
  background: rgba(234, 65, 65, 0.5);
  border-color: transparent;
}

.btn.btn-light-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 65, 65, 0.2);
}

.btn.btn-light-danger.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 65, 65, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-danger:hover {
  -webkit-animation: none;
}

.btn.btn-outline-danger {
  background: none;
  border-color: #ea4141;
  color: #e03737;
  border-width: 1px;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):hover {
  background: #ea4141;
  border-color: #ea4141;
  color: white;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):focus,
.btn.btn-outline-danger:not(:disabled):not(.disabled):active {
  background: #cc2323;
  border-color: #cc2323;
  color: white;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 65, 65, 0.4);
}

.btn.btn-outline-danger.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 65, 65, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-danger:hover {
  -webkit-animation: none;
}

.btn.btn-warning {
  background: #ff9d36;
  border-color: #ff9d36;
}

.btn.btn-warning:not(:disabled):not(.disabled):hover,
.btn.btn-warning:not(:disabled):not(.disabled):focus,
.btn.btn-warning:not(:disabled):not(.disabled):active {
  background: #e17f18;
  border-color: #e17f18;
}

.btn.btn-warning:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 54, 0.4) !important;
}

.btn.btn-warning.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(255, 157, 54, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-warning:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-warning {
  background: -moz- oldlinear-gradient(70deg, #ffc55e, #f08e27);
  background: linear-gradient(20deg, #ffc55e, #f08e27);
  border-color: transparent;
  color: white;
  color: #212529;
}

.btn.btn-gradient-warning:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-warning:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-warning:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #ff9d36, #f08e27);
  background: linear-gradient(20deg, #ff9d36, #f08e27);
  border-color: transparent;
}

.btn.btn-gradient-warning:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 54, 0.5);
}

.btn.btn-gradient-warning.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(255, 157, 54, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-warning:hover {
  -webkit-animation: none;
}

.btn.btn-light-warning {
  background: rgba(255, 157, 54, 0.3);
  border-color: transparent;
  color: #cd6b04;
}

.btn.btn-light-warning:not(:disabled):not(.disabled):hover,
.btn.btn-light-warning:not(:disabled):not(.disabled):focus,
.btn.btn-light-warning:not(:disabled):not(.disabled):active {
  background: rgba(255, 157, 54, 0.5);
  border-color: transparent;
}

.btn.btn-light-warning:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 54, 0.2);
}

.btn.btn-light-warning.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(255, 157, 54, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-warning:hover {
  -webkit-animation: none;
}

.btn.btn-outline-warning {
  background: none;
  border-color: #ff9d36;
  color: #f5932c;
  border-width: 1px;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):hover {
  background: #ff9d36;
  border-color: #ff9d36;
  color: white;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):focus,
.btn.btn-outline-warning:not(:disabled):not(.disabled):active {
  background: #e17f18;
  border-color: #e17f18;
  color: white;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 157, 54, 0.4);
}

.btn.btn-outline-warning.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(255, 157, 54, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-warning:hover {
  -webkit-animation: none;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):hover,
.btn.btn-outline-warning:not(:disabled):not(.disabled):focus,
.btn.btn-outline-warning:not(:disabled):not(.disabled):active {
  color: #212529;
}

.btn.btn-info {
  background: #00bcd4;
  border-color: #00bcd4;
}

.btn.btn-info:not(:disabled):not(.disabled):hover,
.btn.btn-info:not(:disabled):not(.disabled):focus,
.btn.btn-info:not(:disabled):not(.disabled):active {
  background: #009eb6;
  border-color: #009eb6;
}

.btn.btn-info:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4) !important;
}

.btn.btn-info.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-info:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-info {
  background: -moz- oldlinear-gradient(70deg, #28e4fc, #00adc5);
  background: linear-gradient(20deg, #28e4fc, #00adc5);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-info:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-info:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-info:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #00bcd4, #00adc5);
  background: linear-gradient(20deg, #00bcd4, #00adc5);
  border-color: transparent;
}

.btn.btn-gradient-info:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}

.btn.btn-gradient-info.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-info:hover {
  -webkit-animation: none;
}

.btn.btn-light-info {
  background: rgba(0, 188, 212, 0.3);
  border-color: transparent;
  color: #008aa2;
}

.btn.btn-light-info:not(:disabled):not(.disabled):hover,
.btn.btn-light-info:not(:disabled):not(.disabled):focus,
.btn.btn-light-info:not(:disabled):not(.disabled):active {
  background: rgba(0, 188, 212, 0.5);
  border-color: transparent;
}

.btn.btn-light-info:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.2);
}

.btn.btn-light-info.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-info:hover {
  -webkit-animation: none;
}

.btn.btn-outline-info {
  background: none;
  border-color: #00bcd4;
  color: #00b2ca;
  border-width: 1px;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):hover {
  background: #00bcd4;
  border-color: #00bcd4;
  color: white;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):focus,
.btn.btn-outline-info:not(:disabled):not(.disabled):active {
  background: #009eb6;
  border-color: #009eb6;
  color: white;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4);
}

.btn.btn-outline-info.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-info:hover {
  -webkit-animation: none;
}

.btn.btn-light {
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.btn.btn-light:not(:disabled):not(.disabled):hover,
.btn.btn-light:not(:disabled):not(.disabled):focus,
.btn.btn-light:not(:disabled):not(.disabled):active {
  background: #c8c8c8;
  border-color: #c8c8c8;
}

.btn.btn-light:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4) !important;
}

.btn.btn-light.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-light {
  background: -moz- oldlinear-gradient(70deg, white, #d7d7d7);
  background: linear-gradient(20deg, white, #d7d7d7);
  border-color: transparent;
  color: white;
  color: inherit;
}

.btn.btn-gradient-light:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-light:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-light:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #e6e6e6, #d7d7d7);
  background: linear-gradient(20deg, #e6e6e6, #d7d7d7);
  border-color: transparent;
}

.btn.btn-gradient-light:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.5);
}

.btn.btn-gradient-light.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-light:hover {
  -webkit-animation: none;
}

.btn.btn-outline-light {
  background: none;
  border-color: #e6e6e6;
  color: gainsboro;
  border-width: 1px;
  color: #212529;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):hover {
  background: #e6e6e6;
  border-color: #e6e6e6;
  color: white;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):focus,
.btn.btn-outline-light:not(:disabled):not(.disabled):active {
  background: #c8c8c8;
  border-color: #c8c8c8;
  color: white;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4);
}

.btn.btn-outline-light.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-light:hover {
  -webkit-animation: none;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):hover,
.btn.btn-outline-light:not(:disabled):not(.disabled):focus,
.btn.btn-outline-light:not(:disabled):not(.disabled):active {
  color: #212529;
}

.btn.btn-dark {
  background: #3a3f51;
  border-color: #3a3f51;
}

.btn.btn-dark:not(:disabled):not(.disabled):hover,
.btn.btn-dark:not(:disabled):not(.disabled):focus,
.btn.btn-dark:not(:disabled):not(.disabled):active {
  background: #1c2133;
  border-color: #1c2133;
}

.btn.btn-dark:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.4) !important;
}

.btn.btn-dark.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-dark:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-dark {
  background: -moz- oldlinear-gradient(70deg, #626779, #2b3042);
  background: linear-gradient(20deg, #626779, #2b3042);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-dark:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-dark:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-dark:not(:disabled):not(.disabled):active {
  background: -moz- oldlinear-gradient(70deg, #3a3f51, #2b3042);
  background: linear-gradient(20deg, #3a3f51, #2b3042);
  border-color: transparent;
}

.btn.btn-gradient-dark:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.5);
}

.btn.btn-gradient-dark.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-dark:hover {
  -webkit-animation: none;
}

.btn.btn-light-dark {
  background: rgba(58, 63, 81, 0.3);
  border-color: transparent;
  color: #080d1f;
}

.btn.btn-light-dark:not(:disabled):not(.disabled):hover,
.btn.btn-light-dark:not(:disabled):not(.disabled):focus,
.btn.btn-light-dark:not(:disabled):not(.disabled):active {
  background: rgba(58, 63, 81, 0.5);
  border-color: transparent;
}

.btn.btn-light-dark:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.2);
}

.btn.btn-light-dark.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-dark:hover {
  -webkit-animation: none;
}

.btn.btn-outline-dark {
  background: none;
  border-color: #3a3f51;
  color: #303547;
  border-width: 1px;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):hover {
  background: #3a3f51;
  border-color: #3a3f51;
  color: white;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):focus,
.btn.btn-outline-dark:not(:disabled):not(.disabled):active {
  background: #1c2133;
  border-color: #1c2133;
  color: white;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.4);
}

.btn.btn-outline-dark.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-dark:hover {
  -webkit-animation: none;
}

.btn.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: white;
}

.btn.btn-facebook:not(:disabled):not(.disabled):hover,
.btn.btn-facebook:not(:disabled):not(.disabled):focus,
.btn.btn-facebook:not(:disabled):not(.disabled):active {
  background: #1d3b7a;
  border-color: #1d3b7a;
}

.btn.btn-facebook:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.4) !important;
}

.btn.btn-facebook.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-facebook:hover {
  -webkit-animation: none;
}

.btn.btn-outline-facebook {
  background: none;
  border-color: #3b5998;
  color: #314f8e;
  border-width: 1px;
}

.btn.btn-outline-facebook:not(:disabled):not(.disabled):hover {
  background: #3b5998;
  border-color: #3b5998;
  color: white;
}

.btn.btn-outline-facebook:not(:disabled):not(.disabled):focus,
.btn.btn-outline-facebook:not(:disabled):not(.disabled):active {
  background: #1d3b7a;
  border-color: #1d3b7a;
  color: white;
}

.btn.btn-outline-facebook:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.4);
}

.btn.btn-outline-facebook.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-facebook:hover {
  -webkit-animation: none;
}

.btn.btn-google {
  background: #db4437;
  border-color: #db4437;
  color: white;
}

.btn.btn-google:not(:disabled):not(.disabled):hover,
.btn.btn-google:not(:disabled):not(.disabled):focus,
.btn.btn-google:not(:disabled):not(.disabled):active {
  background: #bd2619;
  border-color: #bd2619;
}

.btn.btn-google:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4) !important;
}

.btn.btn-google.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-google:hover {
  -webkit-animation: none;
}

.btn.btn-outline-google {
  background: none;
  border-color: #db4437;
  color: #d13a2d;
  border-width: 1px;
}

.btn.btn-outline-google:not(:disabled):not(.disabled):hover {
  background: #db4437;
  border-color: #db4437;
  color: white;
}

.btn.btn-outline-google:not(:disabled):not(.disabled):focus,
.btn.btn-outline-google:not(:disabled):not(.disabled):active {
  background: #bd2619;
  border-color: #bd2619;
  color: white;
}

.btn.btn-outline-google:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4);
}

.btn.btn-outline-google.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-google:hover {
  -webkit-animation: none;
}

.btn.btn-twitter {
  background: #55acee;
  border-color: #55acee;
  color: white;
}

.btn.btn-twitter:not(:disabled):not(.disabled):hover,
.btn.btn-twitter:not(:disabled):not(.disabled):focus,
.btn.btn-twitter:not(:disabled):not(.disabled):active {
  background: #378ed0;
  border-color: #378ed0;
}

.btn.btn-twitter:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4) !important;
}

.btn.btn-twitter.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-twitter:hover {
  -webkit-animation: none;
}

.btn.btn-outline-twitter {
  background: none;
  border-color: #55acee;
  color: #4ba2e4;
  border-width: 1px;
}

.btn.btn-outline-twitter:not(:disabled):not(.disabled):hover {
  background: #55acee;
  border-color: #55acee;
  color: white;
}

.btn.btn-outline-twitter:not(:disabled):not(.disabled):focus,
.btn.btn-outline-twitter:not(:disabled):not(.disabled):active {
  background: #378ed0;
  border-color: #378ed0;
  color: white;
}

.btn.btn-outline-twitter:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4);
}

.btn.btn-outline-twitter.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-twitter:hover {
  -webkit-animation: none;
}

.btn.btn-linkedin {
  background: #0077b5;
  border-color: #0077b5;
  color: white;
}

.btn.btn-linkedin:not(:disabled):not(.disabled):hover,
.btn.btn-linkedin:not(:disabled):not(.disabled):focus,
.btn.btn-linkedin:not(:disabled):not(.disabled):active {
  background: #005997;
  border-color: #005997;
}

.btn.btn-linkedin:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4) !important;
}

.btn.btn-linkedin.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-linkedin:hover {
  -webkit-animation: none;
}

.btn.btn-outline-linkedin {
  background: none;
  border-color: #0077b5;
  color: #006dab;
  border-width: 1px;
}

.btn.btn-outline-linkedin:not(:disabled):not(.disabled):hover {
  background: #0077b5;
  border-color: #0077b5;
  color: white;
}

.btn.btn-outline-linkedin:not(:disabled):not(.disabled):focus,
.btn.btn-outline-linkedin:not(:disabled):not(.disabled):active {
  background: #005997;
  border-color: #005997;
  color: white;
}

.btn.btn-outline-linkedin:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4);
}

.btn.btn-outline-linkedin.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-linkedin:hover {
  -webkit-animation: none;
}

.btn.btn-whatsapp {
  background: #43d854;
  border-color: #43d854;
  color: white;
}

.btn.btn-whatsapp:not(:disabled):not(.disabled):hover,
.btn.btn-whatsapp:not(:disabled):not(.disabled):focus,
.btn.btn-whatsapp:not(:disabled):not(.disabled):active {
  background: #25ba36;
  border-color: #25ba36;
}

.btn.btn-whatsapp:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4) !important;
}

.btn.btn-whatsapp.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-whatsapp:hover {
  -webkit-animation: none;
}

.btn.btn-outline-whatsapp {
  background: none;
  border-color: #43d854;
  color: #39ce4a;
  border-width: 1px;
}

.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):hover {
  background: #43d854;
  border-color: #43d854;
  color: white;
}

.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):focus,
.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):active {
  background: #25ba36;
  border-color: #25ba36;
  color: white;
}

.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4);
}

.btn.btn-outline-whatsapp.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-whatsapp:hover {
  -webkit-animation: none;
}

.btn.btn-instagram {
  background: #3f729b;
  border-color: #3f729b;
  color: white;
}

.btn.btn-instagram:not(:disabled):not(.disabled):hover,
.btn.btn-instagram:not(:disabled):not(.disabled):focus,
.btn.btn-instagram:not(:disabled):not(.disabled):active {
  background: #21547d;
  border-color: #21547d;
}

.btn.btn-instagram:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4) !important;
}

.btn.btn-instagram.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-instagram:hover {
  -webkit-animation: none;
}

.btn.btn-outline-instagram {
  background: none;
  border-color: #3f729b;
  color: #356891;
  border-width: 1px;
}

.btn.btn-outline-instagram:not(:disabled):not(.disabled):hover {
  background: #3f729b;
  border-color: #3f729b;
  color: white;
}

.btn.btn-outline-instagram:not(:disabled):not(.disabled):focus,
.btn.btn-outline-instagram:not(:disabled):not(.disabled):active {
  background: #21547d;
  border-color: #21547d;
  color: white;
}

.btn.btn-outline-instagram:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4);
}

.btn.btn-outline-instagram.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-instagram:hover {
  -webkit-animation: none;
}

.btn.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: white;
}

.btn.btn-dribbble:not(:disabled):not(.disabled):hover,
.btn.btn-dribbble:not(:disabled):not(.disabled):focus,
.btn.btn-dribbble:not(:disabled):not(.disabled):active {
  background: #cc2e6b;
  border-color: #cc2e6b;
}

.btn.btn-dribbble:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4) !important;
}

.btn.btn-dribbble.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-dribbble:hover {
  -webkit-animation: none;
}

.btn.btn-outline-dribbble {
  background: none;
  border-color: #ea4c89;
  color: #e0427f;
  border-width: 1px;
}

.btn.btn-outline-dribbble:not(:disabled):not(.disabled):hover {
  background: #ea4c89;
  border-color: #ea4c89;
  color: white;
}

.btn.btn-outline-dribbble:not(:disabled):not(.disabled):focus,
.btn.btn-outline-dribbble:not(:disabled):not(.disabled):active {
  background: #cc2e6b;
  border-color: #cc2e6b;
  color: white;
}

.btn.btn-outline-dribbble:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4);
}

.btn.btn-outline-dribbble.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-dribbble:hover {
  -webkit-animation: none;
}

.btn.btn-youtube {
  background: #cd201f;
  border-color: #cd201f;
  color: white;
}

.btn.btn-youtube:not(:disabled):not(.disabled):hover,
.btn.btn-youtube:not(:disabled):not(.disabled):focus,
.btn.btn-youtube:not(:disabled):not(.disabled):active {
  background: #af0201;
  border-color: #af0201;
}

.btn.btn-youtube:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.4) !important;
}

.btn.btn-youtube.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(205, 32, 31, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-youtube:hover {
  -webkit-animation: none;
}

.btn.btn-outline-youtube {
  background: none;
  border-color: #cd201f;
  color: #c31615;
  border-width: 1px;
}

.btn.btn-outline-youtube:not(:disabled):not(.disabled):hover {
  background: #cd201f;
  border-color: #cd201f;
  color: white;
}

.btn.btn-outline-youtube:not(:disabled):not(.disabled):focus,
.btn.btn-outline-youtube:not(:disabled):not(.disabled):active {
  background: #af0201;
  border-color: #af0201;
  color: white;
}

.btn.btn-outline-youtube:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.4);
}

.btn.btn-outline-youtube.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(205, 32, 31, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-youtube:hover {
  -webkit-animation: none;
}

.btn.btn-github {
  background: #00405d;
  border-color: #00405d;
  color: white;
}

.btn.btn-github:not(:disabled):not(.disabled):hover,
.btn.btn-github:not(:disabled):not(.disabled):focus,
.btn.btn-github:not(:disabled):not(.disabled):active {
  background: #00223f;
  border-color: #00223f;
}

.btn.btn-github:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 64, 93, 0.4) !important;
}

.btn.btn-github.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 64, 93, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-github:hover {
  -webkit-animation: none;
}

.btn.btn-outline-github {
  background: none;
  border-color: #00405d;
  color: #003653;
  border-width: 1px;
}

.btn.btn-outline-github:not(:disabled):not(.disabled):hover {
  background: #00405d;
  border-color: #00405d;
  color: white;
}

.btn.btn-outline-github:not(:disabled):not(.disabled):focus,
.btn.btn-outline-github:not(:disabled):not(.disabled):active {
  background: #00223f;
  border-color: #00223f;
  color: white;
}

.btn.btn-outline-github:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 64, 93, 0.4);
}

.btn.btn-outline-github.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 64, 93, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-github:hover {
  -webkit-animation: none;
}

.btn.btn-behance {
  background: #1769ff;
  border-color: #1769ff;
  color: white;
}

.btn.btn-behance:not(:disabled):not(.disabled):hover,
.btn.btn-behance:not(:disabled):not(.disabled):focus,
.btn.btn-behance:not(:disabled):not(.disabled):active {
  background: #004be1;
  border-color: #004be1;
}

.btn.btn-behance:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4) !important;
}

.btn.btn-behance.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-behance:hover {
  -webkit-animation: none;
}

.btn.btn-outline-behance {
  background: none;
  border-color: #1769ff;
  color: #0d5ff5;
  border-width: 1px;
}

.btn.btn-outline-behance:not(:disabled):not(.disabled):hover {
  background: #1769ff;
  border-color: #1769ff;
  color: white;
}

.btn.btn-outline-behance:not(:disabled):not(.disabled):focus,
.btn.btn-outline-behance:not(:disabled):not(.disabled):active {
  background: #004be1;
  border-color: #004be1;
  color: white;
}

.btn.btn-outline-behance:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4);
}

.btn.btn-outline-behance.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-behance:hover {
  -webkit-animation: none;
}

.btn.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: white;
}

.btn.btn-skype:not(:disabled):not(.disabled):hover,
.btn.btn-skype:not(:disabled):not(.disabled):focus,
.btn.btn-skype:not(:disabled):not(.disabled):active {
  background: #0091d2;
  border-color: #0091d2;
}

.btn.btn-skype:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 175, 240, 0.4) !important;
}

.btn.btn-skype.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-skype:hover {
  -webkit-animation: none;
}

.btn.btn-outline-skype {
  background: none;
  border-color: #00aff0;
  color: #00a5e6;
  border-width: 1px;
}

.btn.btn-outline-skype:not(:disabled):not(.disabled):hover {
  background: #00aff0;
  border-color: #00aff0;
  color: white;
}

.btn.btn-outline-skype:not(:disabled):not(.disabled):focus,
.btn.btn-outline-skype:not(:disabled):not(.disabled):active {
  background: #0091d2;
  border-color: #0091d2;
  color: white;
}

.btn.btn-outline-skype:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 175, 240, 0.4);
}

.btn.btn-outline-skype.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-skype:hover {
  -webkit-animation: none;
}

.btn.btn-yahoo {
  background: #410093;
  border-color: #410093;
  color: white;
}

.btn.btn-yahoo:not(:disabled):not(.disabled):hover,
.btn.btn-yahoo:not(:disabled):not(.disabled):focus,
.btn.btn-yahoo:not(:disabled):not(.disabled):active {
  background: #230075;
  border-color: #230075;
}

.btn.btn-yahoo:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 0, 147, 0.4) !important;
}

.btn.btn-yahoo.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(65, 0, 147, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-yahoo:hover {
  -webkit-animation: none;
}

.btn.btn-outline-yahoo {
  background: none;
  border-color: #410093;
  color: #370089;
  border-width: 1px;
}

.btn.btn-outline-yahoo:not(:disabled):not(.disabled):hover {
  background: #410093;
  border-color: #410093;
  color: white;
}

.btn.btn-outline-yahoo:not(:disabled):not(.disabled):focus,
.btn.btn-outline-yahoo:not(:disabled):not(.disabled):active {
  background: #230075;
  border-color: #230075;
  color: white;
}

.btn.btn-outline-yahoo:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 0, 147, 0.4);
}

.btn.btn-outline-yahoo.btn-pulse:not(:disabled):not(.disabled) {
  box-shadow: 0 0 0 0 rgba(65, 0, 147, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-yahoo:hover {
  -webkit-animation: none;
}

.btn.btn-apple,
.btn.btn-google-play {
  border-radius: 7px;
}

.btn.btn-apple img,
.btn.btn-google-play img {
  width: 35px;
  margin-right: 10px;
}

.btn.btn-apple i,
.btn.btn-google-play i {
  font-size: 40px;
  margin-right: 10px;
}

.btn.btn-apple > span,
.btn.btn-google-play > span {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.btn.btn-apple > span span:nth-child(2),
.btn.btn-google-play > span span:nth-child(2) {
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
}

.btn.btn-apple:hover,
.btn.btn-apple:active,
.btn.btn-apple:focus,
.btn.btn-google-play:hover,
.btn.btn-google-play:active,
.btn.btn-google-play:focus {
  background: #040507;
  color: white;
}

.btn.btn-apple {
  border: 1px solid #040507;
  color: #040507;
}

.btn.btn-google-play {
  background: #040507;
  color: white;
}

.btn.btn-google-play > span span:nth-child(1) {
  text-transform: uppercase;
  font-size: 12px;
}

.form-control {
  font-size: 14px;
}

.form-control {
  border-radius: 5px;
}

.form-control:focus {
  border-color: transparent;
  box-shadow: 0 0 0 0.05rem #3db16b;
}

.dark-modal .modal-content {
  background-color: #464852 !important;
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
}

.dark-modal .modal-content .modal-header {
  background-color: #5a5c66 !important;
}

.dark-modal .modal-content {
  border-radius: 5px;
  border: none;
  box-shadow: none;
  overflow: hidden;
}

.dark-modal .modal-content .modal-header {
  align-items: center;
  padding: 20px 30px;
  background-color: whitesmoke;
  border-bottom: none;
}

.dark-modal .modal-content .modal-header .modal-title {
  display: flex;
  align-items: center;
  line-height: 13px;
  font-weight: 600;
}

.dark-modal .modal-content .modal-header .modal-title i,
.dark-modal .modal-content .modal-header .modal-title figure.avatar {
  margin-right: 0.7rem;
  font-size: 17px;
}

.dark-modal .modal-content .modal-header .modal-title + button {
  text-shadow: none;
  font-size: 14px;
  margin: 0;
  background-color: #e1e1e1;
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
}

.dark-modal .modal-content .modal-body {
  padding: 30px;
}

.dark-modal .modal-content .modal-footer {
  border-top: none;
  padding: 30px;
  padding-top: 0;
}

.dark-modal .modal-open .layout {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.dark-modal .modal.fade .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(0.5);
  transform: translate(0, 0) scale(0.5);
}
.nav-item {
  cursor: pointer;
}
.dark-modal .modal.show .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}

.dark-modal .nav-tabs .nav-link.active {
  border-color: #5a5c66;
  border-bottom-color: #464852;
  background-color: #464852;
  /* color: rgba(255, 255, 255, 0.7); */
  color:#1E81FF
 
}

.dark-modal .nav-tabs .nav-link:hover,
.dark-modal .nav-tabs .nav-link:focus {
  border-color: #5a5c66;
  border-bottom-color: #464852;
}

.dark-modal .nav-tabs {
  border-bottom-color: #5a5c66;
}

.light-modal .modal-content {
  background-color: #ffffff !important;
  font-size: 14px;
}

.light-modal .modal-content .modal-header {
  background-color: #f5f5f5 !important;
}

.light-modal .modal-content {
  border-radius: 5px;
  border: none;
  box-shadow: none;
  overflow: hidden;
}

.light-modal .modal-content .modal-header {
  align-items: center;
  padding: 20px 30px;
  background-color: whitesmoke;
  border-bottom: none;
}

.light-modal .modal-content .modal-header .modal-title {
  display: flex;
  align-items: center;
  line-height: 13px;
  font-weight: 600;
}

.light-modal .modal-content .modal-header .modal-title i,
.light-modal .modal-content .modal-header .modal-title figure.avatar {
  margin-right: 0.7rem;
  font-size: 17px;
}

.light-modal .modal-content .modal-header .modal-title + button {
  text-shadow: none;
  font-size: 14px;
  margin: 0;
  background-color: #e1e1e1;
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
}

.light-modal .modal-content .modal-body {
  padding: 30px;
}

.light-modal .modal-content .modal-footer {
  border-top: none;
  padding: 30px;
  padding-top: 0;
}

.light-modal .modal-open .layout {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.light-modal .modal.fade .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(0.5);
  transform: translate(0, 0) scale(0.5);
}

.light-modal .modal.show .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}

.light-modal .nav-tabs .nav-link.active {
  /* color: #495057; */
  color:#1E81FF;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.light .nav-tabs .nav-link.active {
  /* color: #495057; */
  color:#1E81FF;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.light-modal .nav-tabs .nav-link:hover,
.light-modal .nav-tabs .nav-link:focus {
  border-color: #dee2e6;
  border-bottom-color: #dee2e6;
}

.light-modal .nav-tabs {
  border-bottom-color: #dee2e6;
}

.dark-modal input[type='text'],
.dark-modal textarea,
.dark-modal select {
  background-color: #5a5c66;
  color: rgba(255, 255, 255, 0.7);
  border-color: #6e707a;
}

.dark-modal input[type='text']:focus,
.dark-modal textarea:focus,
.dark-modal select:focus {
  color: rgba(255, 255, 255, 0.7);
  background-color: #5a5c66;
  border-color: #aaacb6;
}
/*
.dark-modal input[type='text']::-webkit-input-placeholder,
.dark-modal textarea::-webkit-input-placeholder,
.dark-modal select::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark-modal input[type='text']::-moz-placeholder,
.dark-modal textarea::-moz-placeholder,
.dark-modal select::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark-modal input[type='text']::-ms-input-placeholder,
.dark-modal textarea::-ms-input-placeholder,
.dark-modal select::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark-modal input[type='text']::placeholder,
.dark-modal textarea::placeholder,
.dark-modal select::placeholder {
  color: rgba(255, 255, 255, 0.5);
} */

.light-modal input[type='text'],
.light-modal textarea,
.light-modal select {
  background-color: #ffffff;
  color: #212529;
  border-color: #6e707a;
}

.light-modal input[type='text']:focus,
.light-modal textarea:focus,
.light-modal select:focus {
  color: #212529;
  background-color: #ffffff;
  border-color: #aaacb6;
}
/*
.light-modal input[type='text']::-webkit-input-placeholder,
.light-modal textarea::-webkit-input-placeholder,
.light-modal select::-webkit-input-placeholder {
  color: #5a5c66;
}

.light-modal input[type='text']::-moz-placeholder,
.light-modal textarea::-moz-placeholder,
.light-modal select::-moz-placeholder {
  color: #5a5c66;
}

.light-modal input[type='text']::-ms-input-placeholder,
.light-modal textarea::-ms-input-placeholder,
.light-modal select::-ms-input-placeholder {
  color: #5a5c66;
}

.light-modal input[type='text']::placeholder,
.light-modal textarea::placeholder,
.light-modal select::placeholder {
  color: #5a5c66;
} */

